import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { OnErrorType } from '../../../../types/Input';
import { ValidationResult, Validator } from '../../../../utils/validators';
import {
  checkErrorVisibility,
  checkFocus,
  focusToRef,
  processValidationIfOnError,
} from '../../../../utils/inputFieldHelpers';
import {
  formatAmount,
  isDesktopOrTablet,
  scrollToPosition,
  scrollToTopOfTheElementForMobile,
} from '../../../../utils/helper';
import { ReactComponent as CrossIcon } from '../../../../assets/cross.svg';

export interface AmountInputProps {
  disabled?: boolean;
  disabledJustInput?: boolean;
  placeholder: string;
  validator?: Validator;
  validation?: ValidationResult;
  className?: string;
  value: number;
  onError?: OnErrorType;
  onBlurChange: (value: string) => void;
  disableErrorText?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  inputRegex?: RegExp;
  optional?: boolean;
  optionalText?: string;
}

const AmountInput = ({
  disabled,
  disabledJustInput,
  disableErrorText,
  placeholder,
  validation,
  validator,
  value: propsValue,
  className,
  onBlurChange,
  onError,
  optional,
  optionalText,
  inputRef,
  inputRegex,
}: AmountInputProps) => {
  const amountRef = useRef<HTMLInputElement>(null);
  const currentRef = inputRef || amountRef;

  const [isFocused, setIsFocused] = useState(() => checkFocus(currentRef));
  const [amountInput, setAmountInput] = useState<string | undefined>(() =>
    formatAmount(propsValue?.toString()),
  );

  useEffect(() => {
    setAmountInput(() =>
      formatAmount(propsValue?.toString() === '0' ? '' : propsValue?.toString()),
    );
  }, [propsValue]);

  const handleChange = (value: string) => {
    const regexValue = inputRegex !== undefined ? value.replace(inputRegex, '') : value;

    const validationV = validator?.validate(regexValue);
    setAmountInput(regexValue);
    processValidationIfOnError(onError, validationV);
  };

  const cleanInput = () => {
    handleChange('');
    onBlurChange('');

    focusToRef(currentRef);
  };

  const showErrors = checkErrorVisibility(isFocused, validation?.valid);

  return (
    <div
      className={`amount-input${disabled ? ' disabled' : ''}${
        className ? ` ${className}` : ''
      }${showErrors ? ' error' : ''}`}
      data-testid='amount-input'
    >
      <div className='inner'>
        <input
          className={amountInput ? 'has-value' : ''}
          type='text'
          data-testid='amount-input-field'
          inputMode='decimal'
          value={amountInput ?? ''}
          onChange={(e) => handleChange(e.target.value || '')}
          onFocus={(e) => {
            // inputRef is passed if component is in a modal, order of functions important in that case
            if (!inputRef?.current) {
              scrollToTopOfTheElementForMobile(window.scrollY, e.target.getBoundingClientRect().y);
            } else {
              scrollToPosition('0');
            }
            setIsFocused(true);
          }}
          onBlur={() => {
            onBlurChange(amountInput ?? '');
            setTimeout(() => {
              setIsFocused(() => checkFocus(currentRef));
            }, 100);
          }}
          disabled={disabled}
          readOnly={disabledJustInput}
          ref={currentRef}
        />
        <div className='floating-label'>{`${placeholder}${
          optional && !amountInput && !isFocused ? ` ${optionalText ?? '(optional)'}` : ''
        }`}</div>
        {!isDesktopOrTablet() && amountInput && isFocused && !disabled && (
          <div className='append-inner' data-testid='amount-input-clear'>
            <CrossIcon onClick={cleanInput} />
          </div>
        )}
      </div>
      {showErrors && !disableErrorText && <div className='error-text'> {validation?.message} </div>}
    </div>
  );
};

export default AmountInput;
