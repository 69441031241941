import { ProcessStateEnum } from '../../../../../stores/KwsState/KwsState.type';
import { SubStateEnum } from '../../../../../types/ThanksPage/ThanksPageModel';
import { StateInfoChipEnum } from '../../../../Common/StateInfoChips';

const createSubStateChipData = (subState: SubStateEnum) => {
  switch (subState) {
    case SubStateEnum.SENDING:
      return {
        type: StateInfoChipEnum.SUCCESS,
        label: 'in Durchführung',
        text: 'Wir haben Ihre Eingaben geprüft. Ihre ausgewählten Umzugsschritte werden zum jeweiligen Datum durchgeführt.',
      };
    case SubStateEnum.SENT:
      return {
        type: StateInfoChipEnum.SUCCESS,
        label: 'durchgeführt',
        text: 'Ihre ausgewählten Umzugsschritte wurden zum jeweiligen Datum durchgeführt.',
      };
    case SubStateEnum.COMPLETED:
      return {
        type: StateInfoChipEnum.SUCCESS,
        label: 'abgeschlossen',
        text: 'Ihr Kontoumzug wurde erfolgreich abgeschlossen. Das Hinzufügen neuer Umzugsschritte ist nicht mehr möglich.',
      };
    case SubStateEnum.EXPIRED:
      return {
        type: StateInfoChipEnum.DEFAULT,
        label: 'abgelaufen',
        text: 'Ihr Kontoumzug wurde archiviert. Das Hinzufügen neuer Umzugsschritte ist nicht mehr möglich.',
      };
    default:
      return null;
  }
};

export const createStateChipData = (
  state: ProcessStateEnum,
  newBankName: string,
  subState?: SubStateEnum,
) => {
  if (state === ProcessStateEnum.READY && subState) {
    return createSubStateChipData(subState);
  }

  switch (state) {
    case ProcessStateEnum.READY:
      return {
        type: StateInfoChipEnum.SUCCESS,
        label: 'beauftragt',
        text: 'Ihr Kontoumzug wurde erfolgreich beauftragt. Wir prüfen Ihre Eingaben und bereiten Ihre ausgewählten Umzugsschritte zum jeweiligen Datum vor.',
      };
    case ProcessStateEnum.WAITING_FOR_IBAN_FROM_BANK:
      return {
        type: StateInfoChipEnum.ALERT,
        label: 'IBAN ausstehend',
        text: `Ihre neue ${newBankName} IBAN wird automatisch innerhalb von wenigen Tagen übermittelt, sodass Ihr Kontoumzug durchgeführt werden kann.`,
      };
    case ProcessStateEnum.WAITING_FOR_IBAN_FROM_CUSTOMER:
      return {
        type: StateInfoChipEnum.ALERT,
        label: 'IBAN ausstehend',
        text: `Leider wurde uns Ihre neue ${newBankName} IBAN nicht automatisch übermittelt. Bitte geben Sie Ihre IBAN ein, damit Ihr Kontoumzug durchgeführt werden kann.`,
      };
    default:
      return null;
  }
};
