import React, { useEffect, useState } from 'react';
import { ValidationResult, Validator } from '../../../../utils/validators';
import { scrollToTopOfTheElementForMobile } from '../../../../utils/helper';
import {
  checkErrorVisibility,
  processValidationIfOnError,
} from '../../../../utils/inputFieldHelpers';
import './index.scss';

interface NumberInputProps {
  placeholder: string;
  validator?: Validator;
  validation?: ValidationResult;
  disabled?: boolean;
  className?: string;
  value: string;
  min?: number;
  max?: number;
  inputRef?: any;
  onChange?: (v: string) => void;
  onFocusChange?: (focus: boolean) => void;
  onError?: (validation: ValidationResult) => void;
}

const NumberInput = ({
  placeholder,
  value: propsValue,
  validation,
  validator,
  inputRef,
  disabled,
  className,
  min,
  max,
  onChange,
  onError,
  onFocusChange,
}: NumberInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueV = e.currentTarget.value || '';
    const validationV = validator?.validate(valueV);
    if (onChange) {
      onChange(valueV);
    }
    processValidationIfOnError(onError, validationV);
  };

  useEffect(() => {
    // Prevent scrolling the value for input type number, but it also prevents scrolling the page
    const ignoreScroll = (e: React.WheelEvent<HTMLInputElement>) => {
      e.preventDefault();
    };
    if (inputRef?.current) {
      inputRef.current.addEventListener('wheel', ignoreScroll);
    }

    return () => {
      if (inputRef?.current) {
        inputRef.current.removeEventListener('wheel', ignoreScroll);
      }
    };
  }, [inputRef]);

  const showErrors = checkErrorVisibility(isFocused, validation?.valid);

  return (
    <div
      className={`number-input ${showErrors ? 'error ' : ''}${
        disabled ? 'disabled ' : ''
      }${className ?? ''}`}
      data-testid='number-input'
    >
      <div className='inner'>
        <input
          className={propsValue ? 'has-value' : ''}
          type='number'
          inputMode='numeric'
          value={propsValue}
          disabled={disabled}
          onChange={handleChange}
          min={min}
          max={max}
          onFocus={(e) => {
            if (onFocusChange) {
              onFocusChange(true);
            }
            scrollToTopOfTheElementForMobile(window.scrollY, e.target.getBoundingClientRect().y);
            setIsFocused(true);
          }}
          onBlur={() => {
            if (onFocusChange) {
              onFocusChange(false);
            }
            setIsFocused(false);
          }}
          // allows scrolling the page when the input is focused, but not the value
          onWheel={(event) => window.scrollBy(0, event.deltaY)}
          ref={inputRef}
          data-testid='number-input-field'
        />
        <div className='floating-label'>{placeholder}</div>
      </div>
      {showErrors && <div className='error-text'> {validation?.message} </div>}
    </div>
  );
};

export default NumberInput;
