import React from 'react';
import './index.scss';
import { ReactComponent as CheckIcon } from '../../../../../../../assets/check-in-circle.svg';
import { ReactComponent as RectangleIcon } from '../../../../../../../assets/rectangle.svg';
import { ReactComponent as WaitingIcon } from '../../../../../../../assets/waiting.svg';
import {
  TimelineDetail,
  TimelineStatusEnum,
  TimelineTypeEnum,
} from '../../../../../../../types/Timeline';
import { generatePdfLink } from '../../../../../../../utils/linkGenerator';

export interface TimelineStepDetailProps {
  data: TimelineDetail;
  type?: TimelineTypeEnum;
}

const TimelineStepDetail = ({ data, type }: TimelineStepDetailProps) => {
  function renderIcon() {
    if (type === TimelineTypeEnum.ACCOUNT_TRANSFER_SUMMARY) {
      return <RectangleIcon className='rectangle' />;
    }
    if (data.status === TimelineStatusEnum.COMPLETED) {
      return <CheckIcon className='check' />;
    }
    return <WaitingIcon className='waiting' />;
  }

  return (
    <div className='timeline-step-detail' data-testid='timeline-step-detail'>
      <div className='icon-container'>{renderIcon()}</div>
      <div className='details-container'>
        <span className='header'>{data.name}</span>
        {data.description && <p className='description'>{data.description}</p>}
        {data.link && (
          <a
            href={generatePdfLink(data.link.url, 'downloadPdfSummary')}
            target='_blank'
            rel='noreferrer'
          >
            <span className='link'>{data.link.description}</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default TimelineStepDetail;
