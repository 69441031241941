import React from 'react';
import './index.scss';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg';
import { ReactComponent as CancelIcon } from '../../../assets/cross.svg';

interface RoundButtonProps {
  /**  Optional children to replace the default plus icon */
  children?: React.ReactNode;
  title: string;
  onClick: () => void;
  /** Optional flag to render a cancel button */
  isCancel?: boolean;
}

const RoundButton = ({ children, title, onClick, isCancel }: RoundButtonProps) => (
  <button
    type='button'
    className={`round-button ${isCancel ? 'cancel' : ''}`}
    onClick={onClick}
    tabIndex={0}
    data-testid='round-button'
  >
    <div className='icon'>{children ?? (isCancel ? <CancelIcon /> : <PlusIcon />)}</div>
    <div>{title}</div>
  </button>
);

export default RoundButton;
