import React, { useRef, useState } from 'react';
import { ValidationResult, Validator } from '../../../../utils/validators';
import './index.scss';
import {
  isDesktopOrTablet,
  scrollToPosition,
  scrollToTopOfTheElementForMobile,
} from '../../../../utils/helper';
import { ReactComponent as CrossIcon } from '../../../../assets/cross.svg';
import {
  checkErrorVisibility,
  checkFocus,
  focusToRef,
  processValidationIfOnError,
} from '../../../../utils/inputFieldHelpers';
import { OnErrorType } from '../../../../types/Input';

export interface TextInputProps {
  disabled?: boolean;
  disabledJustInput?: boolean;
  placeholder: string;
  validator?: Validator;
  validation?: ValidationResult;
  className?: string;
  value: string;
  onChange?: (v: string, valid?: boolean) => void;
  onError?: OnErrorType;
  onFocusChange?: (focus: boolean) => void;
  disableErrorText?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  inputRegex?: RegExp;
  inputMode?: any;
  onClick?: () => void;
  onKeyDown?: (event: any) => void;
  optional?: boolean;
  optionalText?: string;
}

const TextInput = ({
  disabled,
  disabledJustInput,
  disableErrorText,
  placeholder,
  validation,
  validator,
  value: propsValue,
  className,
  onChange,
  onError,
  onFocusChange,
  onClick,
  onKeyDown,
  optional,
  optionalText,
  inputMode,
  inputRef,
  inputRegex,
}: TextInputProps) => {
  const textRef = useRef<HTMLInputElement>(inputRef?.current || null);
  const currentRef = inputRef || textRef;
  const [isFocused, setIsFocused] = useState(() => checkFocus(currentRef));

  const handleChange = (value: string) => {
    const regexValue = inputRegex !== undefined ? value.replace(inputRegex, '') : value;
    const validationV = validator?.validate(regexValue);

    if (onChange) {
      onChange(regexValue || '', validationV?.valid);
    }
    processValidationIfOnError(onError, validationV);
  };

  const cleanInput = () => {
    handleChange('');
    // focus to input field after clear
    focusToRef(currentRef);
  };

  const showErrors = checkErrorVisibility(isFocused, validation?.valid);

  return (
    <div
      className={`text-input${disabled ? ' disabled' : ''}${
        className ? ` ${className}` : ''
      }${showErrors ? ' error' : ''}`}
      data-testid='text-input'
    >
      <div className='inner'>
        <input
          className={propsValue ? 'has-value' : ''}
          type='text'
          data-testid='text-input-field'
          inputMode={inputMode ?? 'text'}
          value={propsValue ?? ''}
          onChange={(e) => handleChange(e.target.value || '')}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          onKeyDown={(v) => {
            if (onKeyDown) onKeyDown(v);
          }}
          onFocus={(e) => {
            if (onFocusChange) {
              onFocusChange(true);
            }
            // inputRef is passed if component is in a modal, order of functions important in that case
            if (!inputRef?.current) {
              scrollToTopOfTheElementForMobile(window.scrollY, e.target.getBoundingClientRect().y);
            } else {
              scrollToPosition('0');
            }
            setIsFocused(true);
          }}
          onBlur={() => {
            if (onFocusChange) {
              onFocusChange(false);
            }
            setTimeout(() => {
              setIsFocused(() => checkFocus(currentRef));
            }, 100);
          }}
          disabled={disabled}
          readOnly={disabledJustInput}
          ref={currentRef}
        />
        <div className='floating-label'>{`${placeholder}${
          optional && !propsValue && !isFocused ? ` ${optionalText ?? '(optional)'}` : ''
        }`}</div>
        {!isDesktopOrTablet() && propsValue && isFocused && !disabled && (
          <div className='append-inner' data-testid='text-input-clear'>
            <CrossIcon onClick={cleanInput} />
          </div>
        )}
      </div>
      {showErrors && !disableErrorText && <div className='error-text'> {validation?.message} </div>}
    </div>
  );
};

export default TextInput;
