import React from 'react';
import './index.scss';

interface ReviewStateChipProps {
  label: string;
  classNames?: string;
}

const ReviewStateChip = ({ label, classNames }: ReviewStateChipProps) => (
  <div className={`review-state-chip ${classNames}`} data-testid='review-state-chip'>
    {label}
  </div>
);

export default ReviewStateChip;
