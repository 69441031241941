import React from 'react';
import {
  checkIsTransactionModified,
  formatAmount,
  formatDate,
  removeNegativeSign,
} from '../../../../../../../utils/helper';
import { NotificationModel } from '../../../../../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../../../../../types/Overview/StandingOrderModel';
import './index.scss';
import { ReactComponent as CarretIcon } from '../../../../../../../assets/carret.svg';
import { ReactComponent as TickIcon } from '../../../../../../../assets/verified.svg';
import { useKwsStateActions } from '../../../../../../../stores/KwsState/KwsState.store';
import { ItemState } from '../../../../../../../types/Overview/Common';
import ReviewStateChip from '../ReviewStateChip';

interface ReviewTransactionProps {
  reviewTransaction: NotificationModel | StandingOrderModel;
  /** If we have any notification that can be edited,
   * but the element is not editable we add the padding to keep the dates aligned */
  addPaddingToRight?: boolean;
}

const ReviewTransaction = ({ reviewTransaction, addPaddingToRight }: ReviewTransactionProps) => {
  const { setNotificationToProcess } = useKwsStateActions();
  const { frequencyLocale, id } = reviewTransaction;

  let amount: number | null;
  let description: string;
  let name: string;
  let isEditable: boolean | undefined;
  let isNotificationSent = false;

  if ('normalizedCounterName' in reviewTransaction) {
    // NotificationModel case
    amount = reviewTransaction.amount;
    name = reviewTransaction.normalizedCounterName;
    description = reviewTransaction.categoryLocale;
    isEditable = reviewTransaction.userPermission.editable;
    isNotificationSent = reviewTransaction.state === ItemState.SENT;
  } else {
    name = reviewTransaction.recipientName;
    amount = -1 * reviewTransaction.amount;
    description = reviewTransaction.description;
  }

  const switchingDate = reviewTransaction.dates?.switchingDate;

  const openNotificationEditPage = () => {
    if (isEditable) {
      setNotificationToProcess({
        pageType: 'EDIT',
        id: reviewTransaction.id,
      });
    }
  };

  const isModified = checkIsTransactionModified(id);

  return (
    <div
      className={`review-transaction ${isEditable ? 'editable' : ''}`}
      onClick={openNotificationEditPage}
      data-testid='review-transaction'
    >
      {isNotificationSent && <ReviewStateChip label='informiert' />}

      <div className='wrapper'>
        <div className='data'>
          <div data-testid='review-transaction-name' className='name'>
            {name}
          </div>
          <div data-testid='review-transaction-info' className='info'>
            {description}
          </div>
          {amount ? (
            <div data-testid='review-transaction-amount' className='amount'>
              {`${amount > 0 ? '+' : '-'}${removeNegativeSign(formatAmount(amount.toString()))} €`}
              {` ${frequencyLocale && frequencyLocale}`}
            </div>
          ) : null}
        </div>
        <div className='extra-info'>
          <div
            className={`info-wrapper ${addPaddingToRight && !isEditable ? 'additional-padding' : ''}`}
          >
            {switchingDate && (
              <div data-testid='review-transaction-switching-date' className='date'>
                {formatDate(switchingDate)}
              </div>
            )}
            {isEditable && (
              <div className='carret-icon' data-testid='review-transaction-carret'>
                <CarretIcon className='rotate-90' />
              </div>
            )}
          </div>
        </div>
      </div>
      {isModified && (
        <div className='edit-success' onClick={openNotificationEditPage}>
          <TickIcon className='modified-icon' />
          <div>Daten gespeichert</div>
        </div>
      )}
    </div>
  );
};

export default ReviewTransaction;
