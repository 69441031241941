import React from 'react';
import './index.scss';

import Container from '../../../../Common/Container';
import ReviewTransaction from './components/ReviewTransaction';
import AccountClosing from './components/AccountClosing';
import { isApprovedState, sort } from '../../../../../utils/helper';
import Tooltip from '../../../../Common/Tooltip';
import InnerBorderContainer from '../../../../Common/InnerBorderContainer';
import { NotificationModel } from '../../../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../../../types/Overview/StandingOrderModel';
import InfoBox from '../../../../Common/InfoBox';
import { useFrontendRuleActions } from '../../../../../stores/FrontendRule/FrontendRule.store';
import {
  HintTypes,
  HintSection,
  FrontEndSection,
} from '../../../../../stores/FrontendRule/FrontendRule.type';
import { TooltipEnum } from '../../../../../stores/Tooltip/Tooltip.type';
import { useOverviewData } from '../../../../../stores/Overview/Overview.store';

const ReviewSection = () => {
  const overviewData = useOverviewData();
  const { notifications, standingOrders, closingData } = overviewData!;
  const { decideSectionToShow, decideHintToShow } = useFrontendRuleActions();

  const approvedNotifications = notifications?.filter((notif) => isApprovedState(notif.state));
  const approvedStandingOrders = standingOrders?.filter((so) => isApprovedState(so.state));

  const tempFilteredApprovedNotifications = () => {
    const ssoIbanChangeNotPossibleForC24 = decideHintToShow({
      type: HintTypes.SECTION,
      hint: HintSection.SSO_IBAN_CHANGE_NOT_POSSIBLE_FOR_C24,
    });
    if (ssoIbanChangeNotPossibleForC24) {
      return approvedNotifications.filter(
        (notification: NotificationModel) => notification.normalizedCounterName !== 'Check24 GmbH',
      );
    }
    return approvedNotifications;
  };

  const approvedSortedNotifications: NotificationModel[] =
    approvedNotifications && sort(tempFilteredApprovedNotifications(), 'amount');

  const hasAnyEditableNotification = approvedSortedNotifications.some(
    (notif) => notif.userPermission.editable,
  );

  const approvedSortedSO = approvedStandingOrders && sort(approvedStandingOrders, 'amount');

  const showNotifications = approvedSortedNotifications && approvedSortedNotifications.length > 0;
  const showOrders =
    decideSectionToShow(FrontEndSection.STANDING_ORDERS) &&
    approvedSortedSO &&
    approvedSortedSO.length > 0;

  return (
    <Container>
      <div className='review-section'>
        <div className='header-wrapper'>
          <div className='header'>
            <h2>Übersicht zu Ihrem Kontoumzug</h2>
            <Tooltip tooltip={TooltipEnum.REVIEW_SECTION}>
              <p>
                In dieser Übersicht finden Sie alle aktuell ausgewählten Zahlungspartner und
                Daueraufträge. Bitte beachten Sie, dass die Daueraufträge nur dann in der Übersicht
                zu sehen sind, wenn die bisherige Bank die Löschung und die neue Bank die
                Einrichtung von Daueraufträgen über den CHECK24 Kontoumzug unterstützen.
              </p>
              <br />
              <p>
                Weiterhin sehen Sie in dieser Übersicht die Details zur Schließung des bisherigen
                Kontos, falls Sie diese ausgewählt haben.
              </p>
            </Tooltip>
          </div>
          {approvedNotifications.length > 0 ||
          approvedStandingOrders.length > 0 ||
          isApprovedState(closingData.state) ? (
            <InfoBox type='success' title='Diese Umzugsschritte erledigen wir für Sie' />
          ) : (
            <InfoBox
              type='info-with-icon'
              title='Sie haben keine Umzugsschritte ausgewählt, die wir für Sie durchführen.'
            />
          )}
        </div>
        {showNotifications ? (
          <div className='section'>
            <div className='header'>
              <span>Zahlungspartner</span>
              <span>Umzugsdatum</span>
            </div>
            <InnerBorderContainer>
              {approvedSortedNotifications.map((notification: NotificationModel) => (
                <ReviewTransaction
                  key={notification.id}
                  reviewTransaction={notification}
                  addPaddingToRight={hasAnyEditableNotification}
                />
              ))}
            </InnerBorderContainer>
          </div>
        ) : null}
        {showOrders ? (
          <div className='section'>
            <div className='header'>
              <span>Daueraufträge</span> <span>Einrichtungsdatum</span>
            </div>
            <InnerBorderContainer>
              {approvedSortedSO.map((order: StandingOrderModel) => (
                <ReviewTransaction key={order.id} reviewTransaction={order} />
              ))}
            </InnerBorderContainer>
          </div>
        ) : null}
        {isApprovedState(closingData.state) &&
          decideSectionToShow(FrontEndSection.CLOSE_ACCOUNT) && (
            <div className='section'>
              <div className='header'>Kündigung des bisherigen Girokontos</div>
              <InnerBorderContainer>
                <AccountClosing />
              </InnerBorderContainer>
            </div>
          )}
      </div>
    </Container>
  );
};

export default ReviewSection;
