import React, { useEffect, useState } from 'react';
import './index.scss';
import './tink-styles/fintec-index.styles.scss';
import { createRoot } from 'react-dom/client';
import Modal from '../../../../Common/Modal';
import ServiceGateway from '../../../../../services/ServiceGateway';
import Spinner from '../../../../Common/Spinner';
import { ReactComponent as CarretIcon } from '../../../../../assets/carret.svg';
import { DeviceOutputEnum } from '../../../../../utils/deviceOutput';
import { useKwsState } from '../../../../../stores/KwsState/KwsState.store';

export interface TinkContainerProps {
  tinkToken: string;
  onSuccess: () => void;
  onAbort: () => void;
}

declare const xs2a: any;

const TinkContainer = ({ tinkToken, onSuccess, onAbort }: TinkContainerProps) => {
  const [showTinkModal, setShowTinkModal] = useState('');
  const kwsState = useKwsState();

  function xs2aError(errorCode: any, messages: any, recoverable: any) {
    setTimeout(() => {
      const errorElement = document.getElementsByClassName('xs2a-error')?.[0] as HTMLElement;
      if (errorElement) {
        errorElement.setAttribute('tabIndex', '-1');
        errorElement.focus();
      }
    }, 100);

    ServiceGateway.tinkError(kwsState!.id, tinkToken, {
      type: 'LOGIN',
      errorCode,
      messages,
      recoverable,
    });
  }

  const configureXs2a = (initCallback: () => void) => {
    xs2a.configure({
      'focus-onload': true,
      'validate-privacy-policy': false,
      'open-privacy-policy-in-tab': true,
      'validate-login-credentials': false,
      'reuse-tab-on-redirect': true,
    });

    xs2a.finish(onSuccess);
    xs2a.abort(onAbort);
    xs2a.error(xs2aError);

    function updateLabel(input: any) {
      const parent = input.parentElement;
      if (input.value?.length > 0) {
        parent.classList.add('has-value');
      } else {
        parent.classList.remove('has-value');
      }
    }

    function toggleFocus(input: any, val: any) {
      const parent = input.parentElement;
      if (val) {
        parent.classList.add('focused');
      } else {
        parent.classList.remove('focused');
      }
    }

    xs2a.render(() => {
      const root = document.getElementById('XS2A-Form');

      if (!root) {
        return;
      }

      const textDivs = root.getElementsByClassName('xs2a-text');
      if (textDivs) {
        for (let i = 0; i < textDivs.length; i++) {
          const input = textDivs[i].getElementsByTagName('input')?.[0];
          if (input) {
            input.onkeyup = () => {
              updateLabel(input);
            };
            input.onchange = () => {
              updateLabel(input);
            };
            input.onfocus = () => {
              toggleFocus(input, true);
            };
            input.onblur = () => {
              toggleFocus(input, false);
            };
            updateLabel(input);
          }
        }
      }

      const flickerDivs = root.getElementsByClassName('xs2a-flicker');
      if (flickerDivs) {
        for (let i = 0; i < flickerDivs.length; i++) {
          const input = flickerDivs[i].getElementsByTagName('input')?.[0];
          if (input) {
            input.onkeyup = () => {
              updateLabel(input);
            };
            input.onchange = () => {
              updateLabel(input);
            };
            input.onfocus = () => {
              toggleFocus(input, true);
            };
            input.onblur = () => {
              toggleFocus(input, false);
            };
            updateLabel(input);
          }
        }
      }

      const selectDivs = root.getElementsByClassName('xs2a-select');
      if (selectDivs) {
        for (let i = 0; i < selectDivs.length; i++) {
          const selectDiv = selectDivs[i];
          const svgDiv = document.createElement('div');
          svgDiv.className = 'svg-div';
          selectDiv.appendChild(svgDiv);
          const svg = <CarretIcon className='dropdown-icon' />;
          const rootSvg = createRoot(svgDiv);
          rootSvg.render(svg);
        }
      }

      const autoSubmitLine = root.getElementsByClassName('xs2a-autosubmit-line');
      if (autoSubmitLine?.length) {
        const spinner = <Spinner />;
        autoSubmitLine[0].innerHTML = '';
        createRoot(autoSubmitLine[0]).render(spinner);
      }

      /* prettier-ignore */
      const policyTags = root.querySelector(
            'label[for=\'XS2A-privacy_policy\']'
          )?.getElementsByTagName('a')
      if (policyTags) {
        for (let i = 0; i < policyTags.length; i++) {
          const policyTag = policyTags[i];

          const newTag = document.createElement('a');
          newTag.href = policyTag.href;
          newTag.target = '_blank';
          newTag.append(policyTag.childNodes[0]);
          newTag.addEventListener('click', (e: Event) => {
            if (window.wf_deviceoutput === DeviceOutputEnum.APP) {
              const url = new URL(policyTag.href);
              if (!url.host.includes('check24')) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                setShowTinkModal(policyTag.href);
              }
            }
          });
          policyTag.replaceWith(newTag);
        }
      }
    });

    initCallback();
  };

  const initXs2a = () => {
    xs2a.init();

    setTimeout(() => {
      document.getElementById('XS2A-USER_NAME')?.focus();
    }, 500);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('id', 'xs2aScript');
    script.addEventListener('load', () => {
      configureXs2a(() => initXs2a());
    });
    script.src = 'https://api.xs2a.com/xs2a.js';
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className='tink-container'>
      <div className='tink-widget'>
        <div className='fintec-form' id='XS2A-Form' data-xs2a={tinkToken} />
      </div>

      {showTinkModal && (
        <Modal appearance='full' onClose={() => setShowTinkModal('')} backLabel='zurück'>
          <div className='tink-conditions'>
            <iframe title='tink-conditions' src={showTinkModal} frameBorder='0' />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TinkContainer;
