import React, { useEffect, useState } from 'react';
import ServiceGateway from '../../../services/ServiceGateway';
import { sort } from '../../../utils/helper';
import InvalidDatesCard from './components/InvalidDatesCard';
import ContactCard from '../../Common/ContactCard';
import './index.scss';
import { NotificationModel } from '../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../types/Overview/StandingOrderModel';
import { GlobalDate } from '../../../types/Overview/GlobalDate';
import { useKwsState } from '../../../stores/KwsState/KwsState.store';

interface InvalidSwitchingDatesData {
  globalDate: GlobalDate | null;
  notifications: NotificationModel[];
  standingOrders: StandingOrderModel[];
}

const initialState: InvalidSwitchingDatesData = {
  globalDate: null,
  notifications: [],
  standingOrders: [],
};

const InvalidSwitchingDates = () => {
  const kwsState = useKwsState();
  const [state, setState] = useState(initialState);

  const invalidNotifications = (notifications: NotificationModel[]) => {
    const result = notifications.filter((n: NotificationModel) => n.dates.invalidSwitchingDate);
    result.sort((a, b) => a.category.localeCompare(b.category, 'de'));
    return result;
  };

  const invalidStandingOrders = (standingOrders: StandingOrderModel[]) => {
    const result = standingOrders.filter((n: StandingOrderModel) => n.dates.invalidSwitchingDate);
    result.sort((a, b) => a.category.localeCompare(b.category, 'de'));
    return result;
  };

  useEffect(() => {
    ServiceGateway.getOverviewPage(kwsState!.id).then((response) => {
      const overview = response.data;
      const notifications = invalidNotifications([...overview.notifications]);
      const standingOrders = invalidStandingOrders([...overview.standingOrders]);

      setState({
        ...state,
        globalDate: overview.globalDate,
        notifications,
        standingOrders,
      });
    });
  }, []);

  return state.notifications?.length || state.standingOrders?.length ? (
    <div className='overview' data-testid='invalid-switching-dates'>
      <div>
        <InvalidDatesCard
          notifications={sort(state.notifications, 'amount')}
          standingOrders={sort(state.standingOrders, 'amount')}
          date={state.globalDate}
        />
      </div>
      <ContactCard />
    </div>
  ) : null;
};

export default InvalidSwitchingDates;
