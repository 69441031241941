import { create } from 'zustand';
import { FrontendRuleStore, HintTypes } from './FrontendRule.type';

export const useFrontendRuleStore = create<FrontendRuleStore>((set, get) => ({
  frontendRule: undefined,

  actions: {
    decideSectionToShow: (sectionName) => {
      const { frontendRule } = get();
      return !frontendRule?.hideSections?.includes(sectionName);
    },
    decideHintToShow: ({ type, hint, itemId }) => {
      const { frontendRule } = get();
      return !!frontendRule?.hints?.filter(
        (h) =>
          h.type === type &&
          h.hint === hint &&
          (type === HintTypes.NOTIFICATION ? h.itemId === itemId : true),
      )?.length;
    },
  },
}));

export const useFrontendRuleActions = () => useFrontendRuleStore((state) => state.actions);
