import React from 'react';
import { useToasts } from '../../../stores/Toast/Toast.store';
import ToastBox from '../ToastBox';

const ToastWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const toasts = useToasts();

  return (
    <>
      {toasts.length > 0 && (
        <div className='toast-wrapper'>
          {toasts.slice(0, 3).map((element) => (
            <ToastBox
              id={element.id}
              partnerName={element.partnerName}
              key={element.id}
              isRemoved={element.isRemoved}
              type={element.type}
            />
          ))}
        </div>
      )}
      {children}
    </>
  );
};

export default ToastWrapper;
