import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import ServiceGateway from '../../../../../../services/ServiceGateway';
import {
  Frequency,
  ItemAction,
  ItemState,
  PossibleFrequency,
} from '../../../../../../types/Overview/Common';
import {
  AddStandingOrderDetailModel,
  StandingOrderDetailModel,
  UpdateStandingOrderDetailModel,
} from '../../../../../../types/Overview/StandingOrderModel';
import Validators, { ValidationResult } from '../../../../../../utils/validators';
import {
  formatAmountReverse,
  formatDate,
  setModifiedTransaction,
} from '../../../../../../utils/helper';
import Tooltip from '../../../../../Common/Tooltip';
import Checkbox from '../../../../../Common/Checkbox';
import DateInput from '../../../../../Common/InputFields/DateInput';
import InfoBox from '../../../../../Common/InfoBox';
import TextInput from '../../../../../Common/InputFields/TextInput';
import IbanInput from '../../../../../Common/InputFields/IbanInput';
import SelectInput from '../../../../../Common/InputFields/SelectInput';
import ActionButtons from '../../../../../Common/ActionButtons';
import ContactCard from '../../../../../Common/ContactCard';
import './index.scss';
import { DECIMAL_REGEXP, DESCRIPTION_REGEXP } from '../../../../../../utils/regex';
import {
  useKwsState,
  useKwsStateActions,
  useStandingOrderToProcess,
} from '../../../../../../stores/KwsState/KwsState.store';
import { TooltipEnum } from '../../../../../../stores/Tooltip/Tooltip.type';
import AmountInput from '../../../../../Common/InputFields/AmountInput';

export type StandingOrderDetailTabProps = {
  standingOrderDetailState: StandingOrderDetailModel;
  setStandingOrderDetailState: (item: StandingOrderDetailModel) => void;
};

const StandingOrderDetailTab = ({
  standingOrderDetailState,
  setStandingOrderDetailState,
}: StandingOrderDetailTabProps) => {
  const kwsState = useKwsState();
  const standingOrderToProcess = useStandingOrderToProcess();
  const { setStandingOrderToProcess, setSwitchingDateToProcess } = useKwsStateActions();

  const { pageType } = standingOrderToProcess!;
  const firstRender = useRef(true);

  const [possibleFrequencies, setPossibleFrequencies] = useState<PossibleFrequency[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState<Record<string, ValidationResult>>({});

  const {
    id: soId,
    frequency: soFrequency,
    dates: {
      switchingDate: soSwitchingDate,
      firstExecutionNewBankDate: soFirstExecutionNewBankDate,
      asap: soAsap,
      earliestSwitchingDate: soEarliestSwitchingDate,
    },
    endExecutionDate: soEndExecutionDate,
    unlimitedExecution: soUnlimitedExecution,
  } = standingOrderDetailState;

  const [firstSwitchingDate, setFirstSwitchingDate] = useState<string>(soSwitchingDate);

  const isPageTypeEdit = pageType === 'EDIT';

  const getPossibleFrequencies = async () => {
    const result = await ServiceGateway.getPossibleFrequencies(kwsState!.id);
    setPossibleFrequencies(result.data);
  };

  const fetchFirstExecutionDate = async (
    kwsId: string,
    orderId: string,
    date: string,
    frequency: Frequency,
  ) => {
    const response = await ServiceGateway.firstExecutionDate(kwsId, orderId, date, frequency);
    return response.data;
  };

  const {
    data: firstExecutionNewBankDate,
    isFetched,
    refetch: getFirstExecutionDate,
  } = useQuery({
    queryKey: ['firstExecutionDate', kwsState!.id, soId, soSwitchingDate, soFrequency],
    queryFn: () => fetchFirstExecutionDate(kwsState!.id, soId, soSwitchingDate, soFrequency),
    enabled: false, // Prevents automatic fetching
  });

  useEffect(() => {
    if (isFetched && firstExecutionNewBankDate) {
      setStandingOrderDetailState({
        ...standingOrderDetailState,
        dates: {
          ...standingOrderDetailState.dates,
          firstExecutionNewBankDate,
        },
      });
    }
  }, [firstExecutionNewBankDate, isFetched]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (kwsState?.id && soId && soSwitchingDate && soFrequency) {
      getFirstExecutionDate();
    }
  }, [soSwitchingDate, soFrequency]);

  useEffect(() => {
    getPossibleFrequencies();
  }, []);

  const submit = async () => {
    setIsLoading(true);
    try {
      const data: AddStandingOrderDetailModel = {
        state: standingOrderDetailState.state || ItemState.NOTIFY,
        action: standingOrderDetailState.action || ItemAction.MESSAGE,
        category: standingOrderDetailState.category || 'UNKNOWN',
        asap: soAsap,
        switchingDate: soAsap ? soEarliestSwitchingDate : soSwitchingDate,
        frequency: soFrequency,
        recipientName: standingOrderDetailState.recipientName,
        recipientIban: standingOrderDetailState.recipientIban,
        amount: standingOrderDetailState.amount,
        description: standingOrderDetailState.description,
        originalEndExecutionDate: standingOrderDetailState.endExecutionDate || null,
        unlimitedExecution: soUnlimitedExecution,
      };
      let result;
      if (isPageTypeEdit) {
        const editedData: UpdateStandingOrderDetailModel = {
          id: soId,
          ...data,
        };
        result = await ServiceGateway.updateStandingOrder(kwsState!.id, editedData);
        setModifiedTransaction(soId);
      } else {
        result = await ServiceGateway.createStandingOrder(kwsState!.id, data);
        setModifiedTransaction(result.data.id);
      }
      const isSuccess = result.status >= 200 && result.status < 300;
      if (isSuccess) {
        setStandingOrderToProcess(undefined);
        setSwitchingDateToProcess(false);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        if (error.response?.data?.errors) {
          setValidationError({
            ...validationError,
            ...error.response.data.errors.reduce((acc: any, err: any) => {
              if (err.type === 'FIELD') {
                return {
                  ...acc,
                  [err.key]: { valid: false, message: err.message },
                };
              }
              return acc;
            }, {}),
          });
        }
      }
    }
    setIsLoading(false);
  };

  const goBack = () => {
    setStandingOrderToProcess(undefined);
    setSwitchingDateToProcess(false);
  };

  const onCheck = (checked: boolean) => {
    if (checked) {
      setFirstSwitchingDate(soSwitchingDate);
    }
    setValidationError((validations) => {
      const v = {
        ...validations,
        switchingDate: { valid: true },
      };
      return v;
    });

    setStandingOrderDetailState({
      ...standingOrderDetailState,
      dates: {
        ...standingOrderDetailState.dates,
        asap: checked,
        switchingDate: checked ? soEarliestSwitchingDate : firstSwitchingDate,
      },
    });
  };

  const onCheckEndDate = (checked: boolean) => {
    setValidationError((validations) => {
      const v = {
        ...validations,
        originalEndExecutionDate: { valid: true },
      };
      return v;
    });

    setStandingOrderDetailState({
      ...standingOrderDetailState,
      unlimitedExecution: checked,
      endExecutionDate: checked ? null : soEndExecutionDate,
    });
  };

  const checkIfUserFilledEndDate = () => soUnlimitedExecution || !!soEndExecutionDate;

  const errorCheck = !(
    soSwitchingDate &&
    standingOrderDetailState.recipientName &&
    standingOrderDetailState.recipientIban &&
    standingOrderDetailState.amount &&
    soFrequency &&
    checkIfUserFilledEndDate() &&
    Object.values(validationError).every((item) => item.valid)
  );

  const renderInfoBoxEndDate = () => {
    const isEndDateBeforeSwitchingDate = moment(soEndExecutionDate).isBefore(soSwitchingDate);
    return soEndExecutionDate ? (
      <p>
        {`Letzte Ausführung vom ${isEndDateBeforeSwitchingDate ? 'alten ' : 'neuen'} Girokonto zum ${formatDate(
          soEndExecutionDate,
        )}`}
      </p>
    ) : null;
  };

  return (
    <div className='standing-order-details-tab' data-testid='standing-order-details-tab'>
      <div className='form-container'>
        {pageType === 'ADD' && (
          <div className='description'>
            Bitte tragen Sie die Daten des Dauerauftrags ein, den wir zusätzlich einrichten sollen.
          </div>
        )}

        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Bankdaten des Zahlungsempfängers</div>
            <Tooltip tooltip={TooltipEnum.STANDING_ORDER_RECIPIENT} hasBackground>
              <p>
                Bitte geben Sie hier den Namen und die IBAN des Zahlungsempfängers ein, an den der
                Dauerauftrag überwiesen werden soll. Die Eingabe der BIC ist nicht notwendig und
                wird automatisch ermittelt.
              </p>
            </Tooltip>
          </div>

          <div className='form-input'>
            <TextInput
              className='w-full'
              value={standingOrderDetailState.recipientName}
              onChange={(v) =>
                setStandingOrderDetailState({
                  ...standingOrderDetailState,
                  recipientName: v,
                })
              }
              placeholder='Empfänger'
              validator={Validators.moreThan2()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  recipientName: e,
                }))
              }
              validation={validationError.recipientName}
            />
          </div>
          <div className='form-input'>
            <IbanInput
              className='w-full'
              value={standingOrderDetailState.recipientIban}
              onChange={(v) =>
                setStandingOrderDetailState({
                  ...standingOrderDetailState,
                  recipientIban: v,
                })
              }
              placeholder='IBAN'
              validator={Validators.germanIbanOrAnyForeign()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  recipientIban: e,
                }))
              }
              validation={validationError.recipientIban}
            />
          </div>
        </div>

        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Überweisungsbetrag</div>
            <Tooltip tooltip={TooltipEnum.STANDING_ORDER_AMOUNT} hasBackground>
              <p>
                Bitte geben Sie hier den Betrag ein, der als Dauerauftrag von Ihrem neuen Girokonto
                an den Zahlungsempfänger überwiesen werden soll.
              </p>
            </Tooltip>
          </div>
          <div className='form-input'>
            <AmountInput
              className='w-50p'
              value={standingOrderDetailState.amount}
              placeholder='Betrag'
              inputRegex={DECIMAL_REGEXP}
              onBlurChange={(v) =>
                setStandingOrderDetailState({
                  ...standingOrderDetailState,
                  amount: v ? formatAmountReverse(v) : 0,
                })
              }
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  amount: e,
                }))
              }
              validation={validationError.amount}
              validator={Validators.emptyOrZero()}
            />
            <div className='euro-text'>Euro</div>
          </div>
        </div>

        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Verwendungszweck</div>
            <Tooltip tooltip={TooltipEnum.STANDING_ORDER_PURPOSE} hasBackground>
              <p>
                Bitte geben Sie hier optional den Verwendungszweck ein, der mit Ihrem Dauerauftrag
                in den Transaktionen des Zahlungsempfängers erscheinen soll.
              </p>
            </Tooltip>
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={standingOrderDetailState.description}
              onChange={(v) =>
                setStandingOrderDetailState({
                  ...standingOrderDetailState,
                  description: v ? v.replace(/&/g, '+') : '',
                })
              }
              inputRegex={DESCRIPTION_REGEXP}
              placeholder='Verwendungszweck'
              validator={Validators.moreThan2orNull()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  description: e,
                }))
              }
              validation={validationError.description}
              optional
            />
          </div>
        </div>

        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Ausführungsintervall</div>
            <Tooltip tooltip={TooltipEnum.STANDING_ORDER_FREQUENCY} hasBackground>
              <p>
                Bitte geben Sie hier die Häufigkeit ein, mit der Ihr Dauerauftrag von Ihrem neuen
                Girokonto ausgeführt werden soll. Eine Änderung des Ausführungsintervalls hat
                Einfluss auf das erste Ausführungsdatum von Ihrem neuen Girokonto.
              </p>
            </Tooltip>
          </div>
          <div className='form-input'>
            <SelectInput
              className='w-full'
              placeholder='Ausführungsintervall'
              onChange={(v) =>
                setStandingOrderDetailState({
                  ...standingOrderDetailState,
                  frequency: v as Frequency,
                })
              }
              value={soFrequency}
              options={possibleFrequencies.map((pf) => ({
                value: pf.name,
                placeholder: pf.locale,
              }))}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  frequency: e,
                }))
              }
              validator={Validators.empty()}
              validation={validationError.frequency}
              emptyOption='Ausführungsintervall wählen'
            />
          </div>
        </div>

        {/* Switching date */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>
              {isPageTypeEdit
                ? 'Umzugsdatum für diesen Dauerauftrag'
                : 'Für welches Datum soll der Dauerauftrag eingerichtet werden?'}
            </div>
            <Tooltip tooltip={TooltipEnum.SWITCHING_DATE} hasBackground>
              {isPageTypeEdit ? (
                <p>
                  Das Kontoumzugsdatum des Dauerauftrags können Sie frei wählen. Die erste
                  Ausführung von Ihrem neuen Girokonto wird automatisch berechnet und angezeigt. Die
                  Berechnung erfolgt anhand des bisherigen Ausführungstags und des gewählten
                  Ausführungsintervalls. Sollte das erste Ausführungsdatum auf ein Wochenende oder
                  einen Feiertag fallen, wird der Dauerauftrag am nächsten Bankarbeitstag
                  ausgeführt.
                </p>
              ) : (
                <p>
                  Das Einrichtungsdatum des Dauerauftrags können Sie frei wählen. Die erste
                  Ausführung wird automatisch berechnet und angezeigt. Sollte das Datum des
                  Dauerauftrages an einem Wochenendtag oder an einem Feiertag fallen, wird er am
                  nächsten Bankarbeitstag ausgeführt.
                </p>
              )}
            </Tooltip>
          </div>
          <div className='form-input switching-date'>
            <div className='date-wrapper'>
              <div className='asap-row'>
                <Checkbox
                  onChange={onCheck}
                  checked={soAsap}
                  inputId='asap-checkbox'
                  label={`Schnellstmöglich ${isPageTypeEdit ? 'umziehen' : 'einrichten'}`}
                />
              </div>
              <DateInput
                value={soSwitchingDate}
                onChange={(v) =>
                  setStandingOrderDetailState({
                    ...standingOrderDetailState,
                    dates: {
                      ...standingOrderDetailState.dates,
                      switchingDate: v,
                    },
                  })
                }
                validation={validationError.switchingDate}
                onError={(e) =>
                  setValidationError((validations) => ({
                    ...validations,
                    switchingDate: e,
                  }))
                }
                disabled={soAsap}
              />
            </div>
            {!soAsap && (
              <div className='switching-info'>
                Durch die Änderung des Einrichtungsdatums ändert sich das Datum der Kontoschließung,
                falls diese gewünscht ist. So werden mögliche Rückbuchungen vermieden.
              </div>
            )}
          </div>

          {isPageTypeEdit &&
            soFrequency &&
            soSwitchingDate &&
            validationError.switchingDate?.valid && (
              <InfoBox type='info'>
                <p>{`Erste Ausführung vom neuen Girokonto zum ${formatDate(soFirstExecutionNewBankDate)}`}</p>
              </InfoBox>
            )}
        </div>

        {/* SO End date */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Enddatum für diesen Dauerauftrag</div>
            <Tooltip tooltip={TooltipEnum.STANDING_ORDER_END_DATE} hasBackground>
              <p>
                Bitte geben Sie hier optional das Enddatum ein, an dem der Dauerauftrag letztmalig
                von Ihrem neuen Girokonto überwiesen werden soll. Sollte das letzte Ausführungsdatum
                auf ein Wochenende oder einen Feiertag fallen, wird der Dauerauftrag letztmalig am
                nächsten Bankarbeitstag ausgeführt.
              </p>
            </Tooltip>
          </div>
          <div className='form-input switching-date'>
            <div className='date-wrapper'>
              <div className='asap-row'>
                <Checkbox
                  onChange={onCheckEndDate}
                  checked={soUnlimitedExecution}
                  inputId='end-date-checkbox'
                  label='Ohne Enddatum'
                />
              </div>

              {!soUnlimitedExecution && (
                <DateInput
                  value={soEndExecutionDate || undefined}
                  onChange={(v) =>
                    setStandingOrderDetailState({
                      ...standingOrderDetailState,
                      // BE returns endExecutionDate as state but expects originalEndExecutionDate for update
                      // until BE is updated, we need to keep both fields in sync
                      endExecutionDate: v,
                    })
                  }
                  validation={validationError.originalEndExecutionDate}
                  onError={(e) =>
                    setValidationError((validations) => ({
                      ...validations,
                      originalEndExecutionDate: e,
                    }))
                  }
                  disabled={soUnlimitedExecution}
                />
              )}
            </div>
          </div>

          {!soUnlimitedExecution &&
            soEndExecutionDate &&
            validationError.originalEndExecutionDate?.valid && (
              <InfoBox type='info'>{renderInfoBoxEndDate()}</InfoBox>
            )}
        </div>
      </div>

      <ActionButtons
        isSticky
        isDisabled={errorCheck}
        isLoading={isLoading}
        hasError={errorCheck}
        proceedButton={{ label: 'Dauerauftrag speichern', onClick: submit }}
        cancelButton={{ label: 'Änderungen verwerfen', onCancel: goBack }}
      />

      <ContactCard />
    </div>
  );
};

export default StandingOrderDetailTab;
