import React, { RefObject, useRef, useState } from 'react';
import './index.scss';
import { ValidationResult, Validator } from '../../../../../utils/validators';
import { isDesktopOrTablet, scrollToTopOfTheElementForMobile } from '../../../../../utils/helper';
import {
  checkErrorVisibility,
  checkFocus,
  focusToRef,
  processValidationIfOnError,
} from '../../../../../utils/inputFieldHelpers';
import { ReactComponent as CrossIcon } from '../../../../../assets/cross.svg';
import { ONLY_NUMBERS_REGEXP } from '../../../../../utils/regex';

interface PostfachInputProps {
  disabled?: boolean;
  disabledJustInput?: boolean;
  placeholder: string;
  validator?: Validator;
  validation?: ValidationResult;
  className?: string;
  value: string | null;
  onChange?: (v: string, valid?: boolean) => void;
  onError?: (validation: ValidationResult) => void;
  onFocusChange?: (focus: boolean) => void;
  disableErrorText?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  inputRegex?: RegExp;
  inputMode?: any;
  optional?: boolean;
  optionalText?: string;
}

const PostFachInput = ({
  disabled,
  disableErrorText,
  disabledJustInput,
  placeholder,
  validation,
  validator,
  value: propsValue,
  className,
  onChange,
  onError,
  onFocusChange,
  optional,
  optionalText,
  inputMode,
  inputRef,
  inputRegex,
}: PostfachInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const postfachRef = useRef<HTMLInputElement>(null);
  const currentRef = inputRef || postfachRef;

  const cleanNonNumeric = (val: string | null) =>
    val ? val.replace(ONLY_NUMBERS_REGEXP, '') : val;

  const handleChange = (value: string) => {
    const regexValue = inputRegex ? value.replace(inputRegex, '') : value;
    const validationV = validator?.validate(regexValue);
    if (onChange) {
      onChange(cleanNonNumeric(regexValue) || '', validationV?.valid);
    }
    processValidationIfOnError(onError, validationV);
  };

  const cleanInput = () => {
    handleChange('');
    focusToRef(currentRef);
  };

  const showErrors = checkErrorVisibility(isFocused, validation?.valid);

  return (
    <div
      className={`postfach-input postfach-field { ${showErrors ? 'error ' : ''}${
        disabled ? 'disabled ' : ''
      }${className ?? ''}`}
      data-testid='postfach-input'
    >
      <div className='inner'>
        <input
          data-testid='postfach-input-field'
          className={propsValue ? 'has-value' : ''}
          type='text'
          inputMode={inputMode ?? 'text'}
          value={propsValue ?? ''}
          onChange={(e) => handleChange(e.target.value || '')}
          onFocus={(e) => {
            if (onFocusChange) {
              onFocusChange(true);
            }
            scrollToTopOfTheElementForMobile(window.scrollY, e.target.getBoundingClientRect().y);
            setIsFocused(true);
          }}
          onBlur={() => {
            if (onFocusChange) {
              onFocusChange(false);
            }
            setTimeout(() => {
              setIsFocused(() => checkFocus(currentRef));
            }, 100);
          }}
          disabled={disabled}
          readOnly={disabledJustInput}
          ref={currentRef}
        />
        <div className='floating-label'>{`${placeholder}${
          optional && !propsValue && !isFocused ? ` ${optionalText ?? '(optional)'}` : ''
        }`}</div>
        {!isDesktopOrTablet() && propsValue && isFocused && !disabled && (
          <div className='append-inner' data-testid='clear-icon'>
            <CrossIcon onClick={cleanInput} />
          </div>
        )}
      </div>
      {showErrors && !disableErrorText && <div className='error-text'> {validation?.message} </div>}
    </div>
  );
};

export default PostFachInput;
