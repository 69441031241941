import React from 'react';
import ConfirmationModal from '..';
import './index.scss';

interface GoBackModalProps {
  onCancel: () => void;
  onSubmit: () => void;
}

const GoBackModal = ({ onCancel, onSubmit }: GoBackModalProps) => (
  <ConfirmationModal
    onCancel={onCancel}
    hasCancelButton
    cancelButtonLabel='weiter bearbeiten'
    onSubmit={onSubmit}
    submitLabel='verwerfen'
  >
    <div className='go-back-modal' data-testid='go-back-modal'>
      <p>Änderungen verwerfen?</p>
      <p>
        <b>Achtung:</b> Sie haben Änderungen vorgenommen. Wenn Sie diese nicht speichern möchten,
        drücken Sie auf “verwerfen”.
      </p>
      <p>Andernfalls bleiben Sie auf dieser Seite und bestätigen Sie die Eingaben.</p>
    </div>
  </ConfirmationModal>
);

export default GoBackModal;
