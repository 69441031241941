import { create } from 'zustand';
import { TooltipStore } from './Tooltip.type';
import { useKwsStateStore } from '../KwsState/KwsState.store';

export const useTooltipStore = create<TooltipStore>((set, get) => ({
  selectedTooltip: undefined,

  actions: {
    selectTooltip: (tooltipToSelect) => {
      const { selectedTooltip } = get();
      if (tooltipToSelect === selectedTooltip) {
        set({ selectedTooltip: undefined });
      } else {
        set({ selectedTooltip: tooltipToSelect });
      }
    },
  },
}));

useKwsStateStore.subscribe(
  (state) => [
    state.kwsState,
    state.notificationToProcess,
    state.standingOrderToProcess,
    state.accountToProcess,
    state.switchingDateToProcess,
  ],
  () => {
    useTooltipStore.setState({ selectedTooltip: undefined });
  },
);

export const useSelectedTooltip = () => useTooltipStore((state) => state.selectedTooltip);
export const useTooltipActions = () => useTooltipStore((state) => state.actions);
