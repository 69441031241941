import { create } from 'zustand';
import { isApprovedState, setLastScrollPosition, sort } from '../../utils/helper';
import { NotificationCategoriesModel, OverviewStore } from './Overview.type';
import { useFrontendRuleStore } from '../FrontendRule/FrontendRule.store';

export const useOverviewStore = create<OverviewStore>((set, get) => ({
  overviewData: undefined,
  notificationCategories: undefined,

  actions: {
    categorizeNotifications: (notifications) => {
      const categories: NotificationCategoriesModel[] = [];
      notifications
        .filter((notification) => !isApprovedState(notification.state))
        .forEach((notification) => {
          const category = categories.find((x) => x.category === notification.categoryLocale);
          if (category) {
            category.notifications.push(notification);
          } else {
            categories.push({
              category: notification.categoryLocale,
              notifications: [notification],
            });
          }
        });
      categories.sort((a, b) => a.category.localeCompare(b.category, 'de'));
      categories.forEach((category) => {
        category.notifications.sort((a, b) =>
          a.normalizedCounterName.localeCompare(b.normalizedCounterName, 'de'),
        );
      });
      return categories;
    },

    sortAndCategorizeNotificationsAndSOrders: (notifs, sOrders) => {
      const standingOrders = sOrders.sort((a, b) => {
        if (b.amount !== a.amount) return b.amount - a.amount;
        if (b.recipientName && a.recipientName)
          return b.recipientName.localeCompare(a.recipientName, 'de');
        return 0;
      });

      const notifications = sort(notifs, sessionStorage.getItem('c24-sort-1') ?? 'amount');
      const notificationCategories = get().actions.categorizeNotifications(notifications);

      return { notifications, standingOrders, notificationCategories };
    },

    fillOverviewData: (data) => {
      const { notifications, standingOrders, notificationCategories } =
        get().actions.sortAndCategorizeNotificationsAndSOrders(
          data.notifications,
          data.standingOrders,
        );

      useFrontendRuleStore.setState({ frontendRule: data.frontendRule });
      set({
        overviewData: { ...data, notifications, standingOrders },
        notificationCategories,
      });
    },

    sortNotifications: (value) => {
      setLastScrollPosition();
      const tempData = get().overviewData;
      if (!tempData) return;

      sessionStorage.setItem('c24-sort-1', value);
      const sorted = sort(tempData.notifications, value);
      set({ overviewData: { ...tempData, notifications: sorted } });
    },

    updateCategories: (notification) => {
      const categories = get().notificationCategories;
      if (!categories) return;

      const category = categories.find((c) => c.category === notification.categoryLocale);
      if (!category) return;

      const index = category.notifications.findIndex((n) => n.id === notification.id);
      if (index !== -1) category.notifications[index] = notification;

      set({ notificationCategories: [...categories] });
    },

    updateNotification: (notification, globalDateObj) => {
      setLastScrollPosition();
      const tempData = get().overviewData;
      if (!tempData) return;

      const index = tempData.notifications.findIndex((n) => n.id === notification.id);
      if (index !== -1) tempData.notifications[index] = notification;

      get().actions.updateCategories(notification);
      set({ overviewData: { ...tempData, globalDate: globalDateObj } });
    },

    updateStandingOrder: (standingOrder, globalDateObj) => {
      setLastScrollPosition();
      const tempData = get().overviewData;
      if (!tempData) return;

      const index = tempData.standingOrders.findIndex((so) => so.id === standingOrder.id);
      if (index !== -1) tempData.standingOrders[index] = standingOrder;

      set({ overviewData: { ...tempData, globalDate: globalDateObj } });
    },

    updateClosingData: (closingData) => {
      setLastScrollPosition();
      const tempData = get().overviewData;
      if (!tempData) return;

      set({ overviewData: { ...tempData, closingData } });
    },
  },
}));

export const useOverviewData = () => useOverviewStore((state) => state.overviewData);
export const useNotificationCategories = () =>
  useOverviewStore((state) => state.notificationCategories);
export const useOverviewActions = () => useOverviewStore((state) => state.actions);
