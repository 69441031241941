import React from 'react';
import './index.scss';
import InnerBorderContainer from '../../../../../Common/InnerBorderContainer';
import { formatIbanLook } from '../../../../../../hooks/useIbanFormat';
import { formatDate, setLastScrollPosition } from '../../../../../../utils/helper';
import { ReactComponent as CalendarIcon } from '../../../../../../assets/calendar.svg';
import { ReactComponent as CarretIcon } from '../../../../../../assets/carret.svg';
import {
  useKwsStateActions,
  useSwitchingDateToProcess,
} from '../../../../../../stores/KwsState/KwsState.store';
import { useOverviewData } from '../../../../../../stores/Overview/Overview.store';

const AccountClosingItem = (): JSX.Element => {
  const switchingDateToProcess = useSwitchingDateToProcess();
  const { setAccountToProcess } = useKwsStateActions();

  const overviewData = useOverviewData();
  const { globalDate, closingData } = overviewData!;

  const openAccountClosingEditPage = () => {
    // prevent setScrollPosition coming from SwitchingDateDetails page
    if (!switchingDateToProcess) {
      setLastScrollPosition();
    }
    setAccountToProcess(true);
  };

  return (
    <InnerBorderContainer classNames={switchingDateToProcess ? 'bg-white' : ''}>
      <div
        className='account-closing-item'
        onClick={openAccountClosingEditPage}
        data-testid='account-closing-item'
      >
        <div>
          <CalendarIcon />
        </div>
        <div className='wrapper'>
          <div className='details'>
            <div className='date'>{formatDate(globalDate.accountClosingDate)}</div>
            <div className='information'>{closingData.oldBank?.name}</div>
            <div className='information line-clamp-1'>
              {formatIbanLook(closingData?.oldBank?.iban)}
            </div>
          </div>

          <div className='carret-color'>
            <CarretIcon className='rotate-90' />
          </div>
        </div>
      </div>
    </InnerBorderContainer>
  );
};

export default AccountClosingItem;
