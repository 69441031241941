enum ProcessStateEnum {
  NEW = 'NEW',
  DAC_NEEDED = 'DAC_NEEDED',
  DAC_ANALYSIS_NEEDED = 'DAC_ANALYSIS_NEEDED',
  NOT_POSSIBLE = 'NOT_POSSIBLE',
  EDITING = 'EDITING',
  CLOSING = 'CLOSING',
  SIGNING = 'SIGNING',
  WAITING_FOR_IBAN_FROM_BANK = 'WAITING_FOR_IBAN_FROM_BANK',
  WAITING_FOR_IBAN_FROM_CUSTOMER = 'WAITING_FOR_IBAN_FROM_CUSTOMER',
  READY = 'READY',
  CANCELED = 'CANCELED',
}

enum WorkflowEnum {
  MANUAL_ATTACH_IBAN = 'MANUAL_ATTACH_IBAN',
  DEFAULT = 'DEFAULT',
}

interface KwsState {
  id: string;
  state: ProcessStateEnum;
  processNumber: number;
  workflow: WorkflowEnum;
  invalidSwitchingDates?: boolean;
  transactionTimeframe?: number;
  archived?: boolean;
  ibanSource?: string;
}

type PageType = 'ADD' | 'EDIT';

interface DetailPage {
  pageType: PageType;
  id?: string;
  accountClosingDate?: string;
}

interface KwsStateStore {
  kwsState?: KwsState;
  notificationToProcess?: DetailPage;
  standingOrderToProcess?: DetailPage;
  accountToProcess?: boolean;
  switchingDateToProcess?: boolean;
  actions: {
    setNotificationToProcess: (val: DetailPage | undefined) => void;
    setStandingOrderToProcess: (val: DetailPage | undefined) => void;
    setSwitchingDateToProcess: (val: boolean) => void;
    setAccountToProcess: (val: boolean) => void;
    refreshKwsState: (processId?: string, success?: () => void) => void;
  };
}

export { KwsState, DetailPage, ProcessStateEnum, WorkflowEnum, KwsStateStore };
