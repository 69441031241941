import React from 'react';
import './index.scss';
import InfoBox from '../InfoBox';
import { useFrontendRuleActions } from '../../../stores/FrontendRule/FrontendRule.store';
import { HintTypes, HintSection } from '../../../stores/FrontendRule/FrontendRule.type';

const JointAccountAlert = () => {
  const { decideHintToShow } = useFrontendRuleActions();

  const shouldShowHint = decideHintToShow({
    type: HintTypes.SECTION,
    hint: HintSection.SWITCHING_PROBLEMS_BECAUSE_OF_JOINT_ACCOUNT,
  });

  if (!shouldShowHint) return null;

  return (
    <div className='joint-account-alert' data-testid='joint-account-alert'>
      <InfoBox
        type='alert'
        title='Gemeinschaftskonto erkannt'
        closeButton={{ sessionTitle: 'joint-account-alert' }}
      >
        <p>
          Der Kontoumzug ist aktuell leider nur für Einzelkonten ausgelegt. Bei einem Umzug von
          einem Gemeinschaftskonto kann die Änderung der Bankverbindung oder die Kontokündigung
          fehlschlagen, weshalb wir Ihnen keine „100% Sorglos Garantie“ bieten können.
        </p>

        <p>
          Wir arbeiten daran, den Kontoumzug bald vollumfänglich für Gemeinschaftskonten zu
          ermöglichen.
        </p>
      </InfoBox>
    </div>
  );
};

export default JointAccountAlert;
