import React, { useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';
import { useQuery } from '@tanstack/react-query';
import ServiceGateway from '../../../../services/ServiceGateway';
import Intro from '../../CommonSections/Intro';
import { isDesktopOrTablet, scrollToPosition } from '../../../../utils/helper';
import { StandingOrderDetailModel } from '../../../../types/Overview/StandingOrderModel';
import SpinnerFullPage from '../../../Common/SpinnerFullPage';
import StandingOrderDetailTab from './components/StandingOrderDetailTab';
import AllTransactionsTab from '../../CommonSections/AllTransactionsTab';
import TabButtons from '../../../Common/TabButtons';
import ResetButton, { RESET_BUTTON_INTERSECTION } from '../../../Common/ResetButton';
import Container from '../../../Common/Container';
import './index.scss';
import { IntersectionObserverComponent } from '../../../../hooks/useIntersectionObserver';
import {
  useKwsState,
  useKwsStateActions,
  useStandingOrderToProcess,
} from '../../../../stores/KwsState/KwsState.store';
import GoBackModal from '../../CommonSections/ConfirmationModal/GoBackModal';

const StandingOrderDetails = () => {
  const kwsState = useKwsState();
  const standingOrderToProcess = useStandingOrderToProcess();
  const { setStandingOrderToProcess, setSwitchingDateToProcess } = useKwsStateActions();

  const { pageType, id: soId } = standingOrderToProcess!;
  const [standingOrderDetailState, setStandingOrderDetailState] =
    useState<StandingOrderDetailModel>();
  const [hasShadow, setHasShadow] = useState(false);
  const [hasGoBackModal, setHasGoBackModal] = useState(false);
  const isEditPage = pageType === 'EDIT';
  const isAddPage = pageType === 'ADD';

  const { data: standingOrderResponse, isLoading: isStandingOrderLoading } = useQuery({
    queryKey: ['standing-order-details', kwsState!.id, soId!],
    queryFn: async () => {
      const response = await ServiceGateway.getStandingOrder(kwsState!.id, soId!);
      return response.data;
    },
    enabled: isEditPage,
  });

  const { data: preCreateSOResponse, isLoading: isPreCreateSOLoading } = useQuery({
    queryKey: ['pre-create-standing-order', kwsState!.id],
    queryFn: async () => {
      const response = await ServiceGateway.preCreateStandingOrder(kwsState!.id);
      return { ...response.data, unlimitedExecution: true };
    },
    enabled: isAddPage,
  });

  useEffect(() => {
    if (standingOrderResponse && isEditPage) {
      setStandingOrderDetailState(standingOrderResponse);
    }
  }, [standingOrderResponse]);

  useEffect(() => {
    if (preCreateSOResponse && isAddPage) {
      setStandingOrderDetailState(preCreateSOResponse);
    }
  }, [preCreateSOResponse]);

  useEffect(() => {
    scrollToPosition('0');
  }, []);

  const closePage = () => {
    setStandingOrderToProcess(undefined);
    setSwitchingDateToProcess(false);
  };

  const goBack = () => {
    if (isEditPage && !isEqual(standingOrderResponse, standingOrderDetailState)) {
      setHasGoBackModal(true);
      return;
    }

    if (isAddPage && !isEqual(preCreateSOResponse, standingOrderDetailState)) {
      setHasGoBackModal(true);
      return;
    }

    closePage();
  };

  if (!standingOrderDetailState || isStandingOrderLoading || isPreCreateSOLoading) {
    return <SpinnerFullPage />;
  }

  const tabData = [
    {
      id: 0,
      title: 'Dauerauftrag',
      content: (
        <StandingOrderDetailTab
          standingOrderDetailState={standingOrderDetailState}
          setStandingOrderDetailState={setStandingOrderDetailState}
        />
      ),
    },
    ...(standingOrderDetailState.transactions && standingOrderDetailState.transactions?.length !== 0
      ? [
          {
            id: 1,
            title: 'Transaktionen',
            content: <AllTransactionsTab transactions={standingOrderDetailState!.transactions!} />,
          },
        ]
      : []),
  ];

  const isTabsVisible = tabData.length > 1;

  return (
    <div className='standing-orders-details' data-testid='standing-orders-details'>
      <ResetButton
        title='zurück zur Übersicht'
        onClick={goBack}
        isSticky={!isDesktopOrTablet()}
        hasShadow={hasShadow}
      />

      {/* Item Info */}
      <div className={`intro-standing-order ${isTabsVisible ? 'bottom-margin-zero' : ''}`}>
        <Container>
          {pageType === 'EDIT' ? (
            <Intro data={standingOrderDetailState} pageType='standingOrder' />
          ) : (
            <h2>Dauerauftrag einrichten</h2>
          )}
        </Container>
      </div>
      {isTabsVisible && isDesktopOrTablet() ? null : (
        <IntersectionObserverComponent
          onIntersection={(isIntersecting) => setHasShadow(!isIntersecting)}
          placeOfIntersection={RESET_BUTTON_INTERSECTION}
        />
      )}

      {isTabsVisible ? (
        <div className={isDesktopOrTablet() ? 'stick-to-top' : 'stick-to-top-50'}>
          <TabButtons tabs={tabData} />
        </div>
      ) : (
        tabData[0].content
      )}

      {hasGoBackModal && (
        <GoBackModal onCancel={() => setHasGoBackModal(false)} onSubmit={closePage} />
      )}
    </div>
  );
};

export default StandingOrderDetails;
