import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import {
  CustomerChoice,
  FetchDacResponse,
  InitFinApiResponse,
  ProviderAvailabilityResponse,
  ServiceGatewayInterface,
  ServiceStart,
  ServiceTestResponse,
} from './ServiceGateway.types';
import { Overview } from '../types/Overview/Overview';
import { isProd } from '../utils/environment';
import {
  EditNotificationsModel,
  NotificationDetailModel,
  UpdateNotificationModel,
} from '../types/Overview/NotificationModel';
import {
  AddStandingOrderDetailModel,
  EditStandingOrdersModel,
  StandingOrderDetailModel,
  UpdateStandingOrderDetailModel,
} from '../types/Overview/StandingOrderModel';
import { BankSearchModel, DacPageModel } from '../types/DacPage/DacPageModel';
import { ThanksPageModel } from '../types/ThanksPage/ThanksPageModel';
import { SignaturePageModel } from '../types/SignaturePage/SignaturePageModel';
import { Frequency, RecipientSearchModel } from '../types/Overview/Common';
import { DacProvider } from '../types/DacProvider';
import { DeviceOutputEnum } from '../utils/deviceOutput';

declare const Check24: any;

export const client = axios.create({
  baseURL: '/frontend',
});

const ServiceGateway: ServiceGatewayInterface = {
  startTestCase: (testParams): Promise<AxiosResponse<ServiceTestResponse>> => {
    const {
      ibanSource,
      ibanTarget,
      email,
      invalidSwitchingDates,
      birthdate,
      testcase,
      fintecDac,
      fintecStandingOrders,
      bankId,
      ssoUserId,
      mobileNumber,
      overviewData,
      partner,
    } = testParams;
    return client.post('/testcase', {
      email,
      invalidSwitchingDates,
      birthday: birthdate,
      state: testcase,
      bankIdTarget: bankId,
      ibanSource: ibanSource || undefined,
      ibanTarget: ibanTarget || undefined,
      fintecDac: fintecDac || undefined,
      fintecStandingOrders: fintecStandingOrders || undefined,
      ssoUserId: ssoUserId || undefined,
      mobileNumber: mobileNumber || undefined,
      overviewData: overviewData || undefined,
      partner: partner || undefined,
    });
  },

  startProcess: (processId: string): Promise<AxiosResponse<ServiceStart>> =>
    client.post(`/${processId}/start`),

  getProviderAvailability: (
    provider: DacProvider,
  ): Promise<AxiosResponse<ProviderAvailabilityResponse>> =>
    client.get(`/dac/provider/${provider}/health`),

  updateProvider: (processId: string, provider: DacProvider): Promise<AxiosResponse<DacProvider>> =>
    client.put(`/${processId}/dac/provider?provider=${provider}`),

  resetBankCode: (processId: string): Promise<AxiosResponse<void>> =>
    client.put(`/${processId}/bank-code/reset`),

  getProcessState: (processId: string): Promise<AxiosResponse<any>> =>
    client.get('/page/state', {
      params: {
        process_id: processId,
      },
    }),

  approveProcess: (processId: string): Promise<AxiosResponse<any>> =>
    client.put(`/${processId}/approveProcess`),

  completeEditing: (processId: string): Promise<AxiosResponse<any>> =>
    client.put(`/${processId}/complete-editing`),

  backOnEditing: (processId: string): Promise<AxiosResponse<any>> =>
    client.put(`/${processId}/back-on-editing`),

  resetProcess: (processId: string): Promise<AxiosResponse<void>> =>
    client.put(`/${processId}/reset`),

  editNotifications: (
    processId: string,
    data: CustomerChoice,
  ): Promise<AxiosResponse<EditNotificationsModel>> =>
    client.put(`/${processId}/edit-state-notifications`, data),

  editSwitchingDate: (processId: string, data: any): Promise<AxiosResponse<any>> =>
    client.put(`/${processId}/globalSwitchingDates`, data),

  invalidSwitchingDates: (processId: string, data: any): Promise<AxiosResponse<any>> =>
    client.put(`/${processId}/invalidSwitchingDates`, data),

  editStandingOrders: (
    processId: string,
    data: CustomerChoice,
  ): Promise<AxiosResponse<EditStandingOrdersModel>> =>
    client.put(`/${processId}/edit-state-standing-orders`, data),

  closingData: (processId: string, data: any): Promise<AxiosResponse<any>> =>
    client.put(`/${processId}/closingData`, data),

  editClosingData: (processId: string, selected: boolean): Promise<AxiosResponse<any>> =>
    client.put(`/${processId}/editClosingData?selected=${selected}`),

  attach: (processId: string, birthdate: string): Promise<AxiosResponse<void>> =>
    client.put(`/${processId}/attach?birthday=${birthdate}`),

  attachTargetIban: (processId: string, iban: string): Promise<AxiosResponse<any>> =>
    client.put(`/${processId}/attachTargetIban?targetIban=${iban}`),

  initTink: (processId: string): Promise<AxiosResponse<string>> =>
    client.post(`/${processId}/dac/init`),

  initFinApi: (
    processId: string,
    deviceoutput?: DeviceOutputEnum,
  ): Promise<AxiosResponse<InitFinApiResponse>> =>
    client.get(`/fin-api/init/${processId}?device_output=${deviceoutput}`),

  resetTink: (processId: string): Promise<AxiosResponse<string>> =>
    client.post(`/${processId}/dac/reset`),

  getProvider: (processId: string): Promise<AxiosResponse<DacProvider>> =>
    client.get(`/dac/provider?process_id=${processId}`),

  fetchTinkDac: (processId: string): Promise<AxiosResponse<FetchDacResponse>> =>
    client.put(`/${processId}/dac/fetch`),

  fetchFinApiDac: (processId: string): Promise<AxiosResponse<FetchDacResponse>> =>
    client.get(`/fin-api/fetch/${processId}`),

  tinkError: (processId: string, wizardKey: string, error: any): Promise<AxiosResponse<string>> =>
    client.post(`/${processId}/dac/error?wizardKey=${wizardKey}`, error),

  finApiError: (processId: string): Promise<AxiosResponse<string>> =>
    client.post(`/fin-api/errors/${processId}`),

  attachIbanSource: (processId: string, ibanSource: string): Promise<AxiosResponse<void>> =>
    client.post(`/${processId}/attachIbanSource?ibanSource=${ibanSource}`),

  getOverviewPage: (processId: string): Promise<AxiosResponse<Overview>> =>
    client.get('/page/overview', {
      params: {
        process_id: processId,
      },
    }),

  getDacPage: (processId: string): Promise<AxiosResponse<DacPageModel>> =>
    client.get('/page/dac-needed', {
      params: {
        process_id: processId,
      },
    }),

  getThanksPage: (processId: string): Promise<AxiosResponse<ThanksPageModel>> =>
    client.get('/page/thanks', {
      params: {
        process_id: processId,
      },
    }),

  getSignaturePage: (processId: string): Promise<AxiosResponse<SignaturePageModel>> =>
    client.get('/page/signature', {
      params: {
        process_id: processId,
      },
    }),

  getPossibleFrequencies: (processId: string): Promise<AxiosResponse<any>> =>
    client.get(`/${processId}/getPossibleFrequencies`),

  sign: (
    processId: string,
    deviceType: string,
    deviceName: string,
    imageFile: File,
  ): Promise<AxiosResponse<any>> => {
    const url = `/${processId}/sign?deviceType=${deviceType}&deviceName=${deviceName}`;
    const bodyFormData = new FormData();
    bodyFormData.append('file', imageFile);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return client.post(url, bodyFormData, config);
  },

  getNotification: (
    processId: string,
    notificationId: string,
  ): Promise<AxiosResponse<NotificationDetailModel>> =>
    client.get(`/${processId}/notification/${notificationId}`),

  getStandingOrder: (
    processId: string,
    standingOrderId: string,
  ): Promise<AxiosResponse<StandingOrderDetailModel>> =>
    client.get(`/${processId}/standingOrder/${standingOrderId}`),

  preCreateNotification: (processId: string): Promise<AxiosResponse<NotificationDetailModel>> =>
    client.post(`/${processId}/preCreateNotification`),

  preCreateStandingOrder: (processId: string): Promise<AxiosResponse<any>> =>
    client.post(`/${processId}/preCreateStandingOrder`),

  createNotification: (
    processId: string,
    data: UpdateNotificationModel,
  ): Promise<AxiosResponse<UpdateNotificationModel>> =>
    client.post(`/${processId}/createNotification`, data),

  createStandingOrder: (
    processId: string,
    data: AddStandingOrderDetailModel,
  ): Promise<AxiosResponse<any>> => client.post(`/${processId}/createStandingOrder`, data),

  updateNotification: (
    processId: string,
    data: UpdateNotificationModel,
  ): Promise<AxiosResponse<UpdateNotificationModel>> =>
    client.put(`/${processId}/updateNotification`, data),

  updateStandingOrder: (
    processId: string,
    data: UpdateStandingOrderDetailModel,
  ): Promise<AxiosResponse<any>> => client.put(`/${processId}/updateStandingOrder`, data),

  logout: (processId: string): Promise<AxiosResponse<string>> => client.get(`/${processId}/logout`),

  downloadPowerOfAttorney: (processId: string): Promise<AxiosResponse<any>> => {
    const c24BankCookie = Cookies.get('c24bank-session');
    return client.get(
      `/${processId}/powerOfAttorney${c24BankCookie ? `?token=${c24BankCookie}` : ''}`,
    );
  },

  getPossibleBankList: (
    searchTerm: string,
    page = 1,
    size = 10,
  ): Promise<AxiosResponse<BankSearchModel>> =>
    client.get('/bank/search', { params: { search_term: searchTerm, page, size } }),

  getPossibleRecipientList: (searchTerm: string): Promise<AxiosResponse<RecipientSearchModel[]>> =>
    client.get('/recipient/search', {
      params: {
        name: searchTerm,
      },
    }),

  attachBankCodeSource: (processId, bankCode): Promise<AxiosResponse<any>> =>
    client.put(`/${processId}/attachBankCodeSource?bankCode=${bankCode}`),

  sendSmsCode: (processId: string): Promise<AxiosResponse<any>> =>
    client.post(`/${processId}/sendSmsCode`),

  verifySms: (processId: string, token: string, code: string): Promise<AxiosResponse<any>> =>
    client.post(`/${processId}/verify`, { token, code }),

  firstExecutionDate: (
    processId: string,
    standingOrderId: string,
    switchingDate: string,
    frequency: Frequency,
  ): Promise<AxiosResponse<any>> =>
    client.get(
      `/${processId}/first-execution-date-with-frequency/${standingOrderId}?switching_date=${switchingDate}&frequency=${frequency}`,
    ),

  // #region Thanks Page endpoints
  updateThanksPageNotification: (
    processId: string,
    data: UpdateNotificationModel,
  ): Promise<AxiosResponse<UpdateNotificationModel>> =>
    client.post(`/thanks/${processId}/notification/update-details`, data),

  cancelNotification: (processId: string, notificationId: string): Promise<AxiosResponse<void>> =>
    client.post(`/thanks/${processId}/notification/${notificationId}/cancel`),

  updateThanksPageClosingDate: (processId: string, date: string): Promise<AxiosResponse<void>> =>
    client.post(`/thanks/${processId}/closing-data/update-account-closing-date`, date, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  cancelAccountClosing: (processId: string): Promise<AxiosResponse<void>> =>
    client.post(`/thanks/${processId}/closing-data/cancel`),
  // #endregion
};

const clearCookies = () => {
  Cookies.remove('c24session', { path: '/' });
  Cookies.remove('c24bank-session', {
    path: '/',
  });

  if (typeof Check24 !== 'undefined') {
    Check24.cookieBanner?.c24consent?.unsetCookie('c24session');
  }
};

export const logoutSystem = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const processId = urlParams.get('id');
  ServiceGateway.logout(processId!).then(() => {
    clearCookies();
    window.location.reload();
  });
};

client.interceptors.request.use((request) => {
  const urlParams = new URLSearchParams(window.location.search);
  const channel = urlParams.get('channel');
  const device = window.wf_deviceoutput;

  if (request.headers) {
    if (device) request.headers.device = device;
    if (channel) request.headers.channel = channel;
    request.headers['Cache-Control'] = 'no-cache';
  }
  return request;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response || {};
    if (status === 410) {
      if (window.top) {
        if (isProd()) {
          window.top.postMessage({ urlChange: data }, 'https://banking.c24.de/');
        } else {
          window.top.postMessage({ urlChange: data }, '*');
        }
      }
      clearCookies();
      window.location.href = data;
    } else if (status === 401 || status === 406) {
      clearCookies();
      window.location.reload();
    } else if (status > 400 || error.message === 'Network Error') {
      // 400 status used for form validations
      window.location.href = `${window.location.origin}/start/error`;
    }
    return Promise.reject(error);
  },
);

export default ServiceGateway;
