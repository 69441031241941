import React from 'react';
import './index.scss';

import Container from '../../../../Common/Container';
import Tooltip from '../../../../Common/Tooltip';
import TransferBankCard from '../../../../Common/TransferBankCard';
import { WorkflowEnum } from '../../../../../stores/KwsState/KwsState.type';
import { useKwsState } from '../../../../../stores/KwsState/KwsState.store';
import { TooltipEnum } from '../../../../../stores/Tooltip/Tooltip.type';
import { useOverviewData } from '../../../../../stores/Overview/Overview.store';
import JointAccountAlert from '../../../../Common/JointAccountAlert';

const WelcomeSection = () => {
  const kwsState = useKwsState();
  const overviewData = useOverviewData();
  const { closingData } = overviewData!;

  return (
    <Container>
      <div className='welcome-banner-section'>
        <div className='header-block'>
          <div className='title-wrapper'>
            <h2 className='text'>Übersicht zu Ihrem Kontoumzug</h2>
            <Tooltip tooltip={TooltipEnum.WELCOME_BANNER}>
              {kwsState?.workflow !== WorkflowEnum.MANUAL_ATTACH_IBAN ? (
                <>
                  <p data-testid='default-workflow-tooltip'>
                    Wir haben aus Ihrem Kontoauszug die für den Umzug wichtigsten Zahlungspartner
                    identifiziert und Ihre Daueraufträge ausgelesen.
                  </p>
                  <p>
                    Auf dieser Seite können Sie die empfohlenen Umzugsschritte prüfen und anpassen
                    bzw. ergänzen.
                  </p>
                  <br />
                  <p>
                    Erst mit dem Klick auf „Kontoumzug beauftragen“ informieren wir Ihre
                    Zahlungspartner, richten Ihre Daueraufträge ein und schließen Ihr bisheriges
                    Konto - sofern von Ihnen gewünscht.
                  </p>
                </>
              ) : (
                <>
                  <p data-testid='manual-workflow-tooltip'>
                    Der manuelle Kontoumzug bietet Ihnen die Möglichkeit, Zahlungspartner auf Ihr
                    neues Konto umzuziehen und die Schließung Ihres bisherigen Kontos zu
                    beauftragen.
                  </p>
                  <br />
                  <p>
                    Erst mit dem Klick auf „Kontoumzug beauftragen“ informieren wir Ihre
                    Zahlungspartner, richten Ihre Daueraufträge ein und schließen Ihr bisheriges
                    Konto - sofern von Ihnen gewünscht.
                  </p>
                </>
              )}
            </Tooltip>
          </div>
          <p className='text-description'>
            {kwsState?.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN
              ? 'Wir haben Ihren Kontoumzug vorbereitet. Bitte konfigurieren Sie diesen individuell nach Ihren Wünschen.'
              : 'Wir haben die Umsätze Ihres bisherigen Kontos analysiert und Ihren Kontoumzug vorbereitet.'}
          </p>
          <TransferBankCard oldBank={closingData.oldBank} newBank={closingData.newBank} />

          <JointAccountAlert />
        </div>
      </div>
    </Container>
  );
};

export default WelcomeSection;
