enum TooltipEnum {
  NOTIFICATION_HEADER,
  NOTIFICATION_ACCOUNT,
  NOTIFICATION_PRIVATE,
  NOTIFICATION_PARTNERS,
  NOTIFICATION_PAYMENT_DETAILS,
  NOTIFICATION_DETAILS_HEADER,
  NOTIFICATION_DETAILS_REFERENCE_NUMBER,
  NOTIFICATION_DETAILS_ADDRESS,
  NOTIFICATION_DETAILS_CUSTOMER,
  ACCOUNT_CLOSING_HEADER,
  ACCOUNT_CLOSING_FORM,
  STANDING_ORDER,
  STANDING_ORDER_DETAILS,
  STANDING_ORDER_RECIPIENT,
  STANDING_ORDER_AMOUNT,
  STANDING_ORDER_PURPOSE,
  STANDING_ORDER_FREQUENCY,
  STANDING_ORDER_END_DATE,
  DAC_HEADER,
  MANUAL_KWS,
  DIGITAL_SIGNATURE,
  SWITCHING_DATE,
  SWITCHING_DATE_HEADER,
  WELCOME_BANNER,
  ALERT_SECTION,
  MISSING_IBAN,
  GUARANTEE_SECTION,
  REVIEW_SECTION,
  SUCCESS_SECTION,
  TIMELINE_SECTION,
  TOGGLE_SECTION,
}

interface TooltipStore {
  selectedTooltip?: TooltipEnum;
  actions: {
    selectTooltip: (tooltip: TooltipEnum) => void;
  };
}

export { TooltipEnum, TooltipStore };
