import React, { useEffect, useRef, useState } from 'react';
import useIbanFormat, { formatIbanLook } from '../../../../hooks/useIbanFormat';
import { IbanInputProps } from './index.types';
import { isDesktopOrTablet, scrollToTopOfTheElementForMobile } from '../../../../utils/helper';
import { ReactComponent as CrossIcon } from '../../../../assets/cross.svg';
import './index.scss';
import {
  checkErrorVisibility,
  focusToRef,
  processValidationIfOnError,
} from '../../../../utils/inputFieldHelpers';
import { SPACE_REGEXP } from '../../../../utils/regex';

const IbanInput = ({
  className,
  validator,
  validation,
  placeholder,
  value: propsValue,
  onChange,
  onError,
}: IbanInputProps): JSX.Element => {
  const [ibanFormattedValue, setIbanFormattedValue] = useState(propsValue);
  const [isFocused, setIsFocused] = useState(false);
  const ibanRef = useRef<HTMLInputElement>(null);

  const { formattedValue, handleIbanInput } = useIbanFormat(propsValue || '');

  const handleOnChange = (value: string) => {
    const spaceCleanedValue = value?.replace(SPACE_REGEXP, '');

    if (spaceCleanedValue?.length <= 36) {
      handleIbanInput({ target: { value } }, false);
      setIbanFormattedValue(value?.length ? formattedValue : '');
      onChange(spaceCleanedValue);
    }

    const validationV = validator?.validate(spaceCleanedValue);
    processValidationIfOnError(onError, validationV);
  };

  const cleanInput = () => {
    handleOnChange('');
    focusToRef(ibanRef);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 100);
  };

  useEffect(() => {
    if (propsValue) {
      setIbanFormattedValue(formatIbanLook(propsValue));
    }
  }, [propsValue]);

  const showErrors = checkErrorVisibility(isFocused, validation?.valid);

  return (
    <div className={`iban-input ${showErrors ? 'error ' : ''} ${className}`}>
      <div className='inner'>
        <input
          data-testid='iban-input'
          className={ibanFormattedValue ? 'has-value' : ''}
          type='text'
          value={ibanFormattedValue ?? ''}
          onChange={(e) => handleOnChange(e.target.value || '')}
          onBlur={handleBlur}
          onFocus={(e) => {
            setIsFocused(true);
            scrollToTopOfTheElementForMobile(window.scrollY, e.target.getBoundingClientRect().y);
          }}
          ref={ibanRef}
        />
        <div data-testid='iban-placeholder' className='floating-label'>{`${placeholder}`}</div>
        {!isDesktopOrTablet() && ibanFormattedValue && isFocused && (
          <div className='append-inner' data-testid='clear-icon'>
            <CrossIcon onClick={cleanInput} />
          </div>
        )}
      </div>
      {showErrors && (
        <div data-testid='iban-error-text' className='error-text'>
          {validation?.message}
        </div>
      )}
    </div>
  );
};

export default IbanInput;
