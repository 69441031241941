import React from 'react';
import { QueryClient, QueryClientConfig, QueryClientProvider } from '@tanstack/react-query';

export const defaultQueryConfig = {
  refetchOnMount: false, // Disable refetching on mount
  refetchOnWindowFocus: false, // Disable refetching when window gains focus
  refetchOnReconnect: false, // Disable refetching on network reconnect
  staleTime: Infinity, // Ensure the data is never considered stale
  gcTime: 0, // Disable garbage collection
};

const queryClientWrapper = (
  children: React.ReactNode,
  isApp?: boolean,
  queryClientConfig?: QueryClientConfig,
) => {
  const testConfig = {
    defaultOptions: {
      queries: {
        retry: false, // Avoid retrying to simplify testing
      },
    },
  };

  const queryClient = new QueryClient(isApp ? queryClientConfig : testConfig);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default queryClientWrapper;
