import React from 'react';
import Container from '../../../../Common/Container';
import Tooltip from '../../../../Common/Tooltip';
import TimelineStep from './components/TimelineStep';
import { Timeline } from '../../../../../types/Timeline';
import { TooltipEnum } from '../../../../../stores/Tooltip/Tooltip.type';

export interface TimelineSectionProps {
  timeline: Timeline;
}

const TimelineSection = ({ timeline }: TimelineSectionProps): JSX.Element => (
  <Container>
    <div className='timeline-section' data-testid='timeline-section'>
      <div className='section-header'>
        <div className='header-block'>
          <h2>Ablauf Ihres Kontoumzugs</h2>
          <Tooltip tooltip={TooltipEnum.TIMELINE_SECTION}>
            <p>Wir haben hier für Sie den Ablauf und Status Ihres Kontoumzugs zusammengefasst.</p>
          </Tooltip>
        </div>
      </div>
      <div className='timeline-steps' data-testid='timeline-steps'>
        {timeline?.steps.map((s, i) => <TimelineStep key={i} step={s} />)}
      </div>
    </div>
  </Container>
);

export default TimelineSection;
