import { ItemState, UserPermission } from './Common';

export enum C24AccountType {
  GIRO = 'giro',
  JOINT_GIRO = 'joint_giro',
  POCKET = 'pocket_plus',
  JOINT_POCKET = 'joint_pocket',
}
export interface OverviewBankModel {
  name: string;
  accountHolderName: string;
  iban: string;
  bic: string;
  balance: string;
  logoName?: string;
  bankCode?: string;
  accountName?: string;
  accountType?: C24AccountType;
  accountClosingLink?: string;
}

export interface ClosingDataModel {
  oldBank: OverviewBankModel;
  newBank: OverviewBankModel;
  state: ItemState | null;
  userPermission?: UserPermission;
}
