import React, { useState } from 'react';
import './index.scss';

import Container from '../../../../Common/Container';
import { ReactComponent as CarretIcon } from '../../../../../assets/carret.svg';
import Tooltip from '../../../../Common/Tooltip';
import { TooltipEnum } from '../../../../../stores/Tooltip/Tooltip.type';

interface ToggleSectionProps {
  children?: React.ReactNode;
}

const ToggleSection = ({ children }: ToggleSectionProps) => {
  const [expand, setExpand] = useState(!!(sessionStorage.getItem('c24-review') === 'true'));

  const toggleExpand = (value: boolean) => {
    sessionStorage.setItem('c24-review', value.toString());
    setExpand(value);
  };

  return (
    <div className='toggle-section' data-testid='toggle-section'>
      <Container>
        <div className='section-header'>
          <div className='header-block'>
            <h2>Umzugsschritte hinzufügen</h2>
            <Tooltip tooltip={TooltipEnum.TOGGLE_SECTION}>
              <p>
                Mit dem Klick auf „Umzugsschritte auswählen“ können Sie zusätzliche Zahlungspartner
                informieren, weitere Daueraufträge umziehen und – falls noch nicht geschehen – Ihr
                altes Konto schließen.
              </p>
            </Tooltip>
          </div>
          <p>
            Bei Bedarf können wir jederzeit bisher noch nicht ausgewählte Umzugsschritte für Sie
            durchführen.
          </p>
        </div>
        <div onClick={() => toggleExpand(!expand)} className='toggle' data-testid='toggle'>
          <div>weitere Umzugsschritte auswählen</div>
          <CarretIcon className={expand ? 'rotate-0' : 'rotate-180'} />
        </div>
      </Container>

      {expand && (
        <div className='toggle-section-content' data-testid='toggle-section-content'>
          {children}
        </div>
      )}
    </div>
  );
};

export default ToggleSection;
