import React, { useEffect } from 'react';
import { isDesktopOrTablet } from '../../../../../utils/helper';
import { isProd } from '../../../../../utils/environment';

export interface FinApiContainerProps {
  finApiToken: string;
  onSuccess: () => void;
  onAbort: () => void;
}

declare const FinApiWebForm: any;

const FinApiContainer = ({ finApiToken, onSuccess, onAbort }: FinApiContainerProps) => {
  const createWebForm = () => {
    const finApiContainer = document.querySelector('#webFormContainer');

    FinApiWebForm?.load(
      finApiContainer,
      {
        token: finApiToken,
        language: 'de',
        targetEnvironment: isProd() ? 'live' : 'sandbox',
        layoutConfig: isDesktopOrTablet() ? 'sm' : 'xs',
        noMargins: 'true',
      },
      {
        onComplete: onSuccess,
        onFail: onAbort,
        onLoadError: onAbort,
        onAbort,
      },
      document,
    );
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://webform-dist.finapi.io/latest/dist/web-form.min.js';
    script.addEventListener('load', () => {
      if (finApiToken) {
        createWebForm();
      }
    });
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className='finapi-container'>
      <div id='webFormContainer' />
    </div>
  );
};

export default FinApiContainer;
