import React from 'react';
import { formatDate } from '../../../../../../../utils/helper';
import './index.scss';
import { useOverviewData } from '../../../../../../../stores/Overview/Overview.store';
import { ReactComponent as CarretIcon } from '../../../../../../../assets/carret.svg';
import { useKwsStateActions } from '../../../../../../../stores/KwsState/KwsState.store';
import { ItemState } from '../../../../../../../types/Overview/Common';
import ReviewStateChip from '../ReviewStateChip';

const AccountClosing = () => {
  const overviewData = useOverviewData();
  const { setAccountToProcess } = useKwsStateActions();
  const { closingData, globalDate } = overviewData!;

  const isEditable = closingData.userPermission?.editable;

  return (
    <div
      className={`account-closing ${isEditable ? 'editable' : ''}`}
      onClick={() => {
        if (isEditable) {
          setAccountToProcess(true);
        }
      }}
      data-testid='account-closing'
    >
      {closingData.state === ItemState.SENT && <ReviewStateChip label='versendet' />}

      <div className='closing-info-wrapper'>
        <div className='title'>Ja, zum {formatDate(globalDate.accountClosingDate)}</div>
        <div className='info'>Restsaldo wird verrechnet</div>
      </div>
      {closingData.userPermission?.editable && (
        <div className='carret-color' data-testid='account-closing-carret'>
          <CarretIcon className='rotate-90' />
        </div>
      )}
    </div>
  );
};

export default AccountClosing;
