import React, { useRef, useState } from 'react';
import Validators, { ValidationResult } from '../../../../utils/validators';
import './index.scss';
import TextInput from '../TextInput';
import PostFachInput from './Postfach/index';
import { DECIMAL_REGEXP } from '../../../../utils/regex';

interface AddressInputProps {
  addressProps: AddressState;
  onAddressChange: (addressState: AddressState) => void;
  /** Validators are handled in a utility file */
  validations?: Record<string, ValidationResult>;
  onError?: (validation: ValidationResult, fieldName: string) => void;
  /** Shows a static hint to make sure the user checks the address */
  showHintField?: 'INITIALLY' | 'AFTER_FOCUS' | 'NOT_SHOW';
  /** Private notifications are used for non-company recipients (Private Person)
   * and this effect the validation rules only */
  isPrivateNotification?: boolean;
  isDisabled?: boolean;
  withPostfach?: boolean;
}

export interface AddressState {
  street?: string;
  city?: string;
  streetNum?: string;
  postalCode?: string;
  postfach?: string;
}

const AddressInput = ({
  addressProps,
  onAddressChange,
  validations,
  onError,
  showHintField = 'NOT_SHOW',
  isPrivateNotification = false,
  isDisabled,
  withPostfach,
}: AddressInputProps) => {
  const [streetFocus, setStreetFocus] = useState(false);
  const [streetNumFocus, setStreetnumFocus] = useState(false);
  const [postalCodeFocus, setPostalCodeFocus] = useState(false);
  const [cityFocus, setCityFocus] = useState(false);
  const firstTimeShowHintsForAddressFields = useRef(showHintField === 'INITIALLY');

  const decideToShowHint = () =>
    showHintField !== 'NOT_SHOW' &&
    firstTimeShowHintsForAddressFields.current && (
      <div className='hint-field'>
        Bitte überprüfen Sie die Anschrift auf Vollständigkeit und Richtigkeit.
      </div>
    );

  return (
    <div className='address-input'>
      <div className='street-streetNo-field'>
        <div className='input-field'>
          <TextInput
            className='w-66p'
            onChange={(v) => onAddressChange({ street: v })}
            value={addressProps?.street || ''}
            placeholder='Straße'
            validator={
              isPrivateNotification ? Validators.moreThan2() : Validators.moreThan2orNull()
            }
            validation={validations?.street}
            onError={(e: ValidationResult) => {
              if (onError) {
                onError(e, 'street');
              }
            }}
            disableErrorText
            onFocusChange={(f) => {
              setStreetFocus(f);
              firstTimeShowHintsForAddressFields.current = true;
            }}
            disabled={isDisabled}
          />
          <TextInput
            className='w-33p'
            value={addressProps?.streetNum || ''}
            onChange={(v) =>
              onAddressChange({
                streetNum: v,
              })
            }
            placeholder='Nr.'
            onError={(e: ValidationResult) => {
              if (onError) {
                onError(e, 'streetNum');
              }
            }}
            validation={validations?.streetNum}
            disableErrorText
            validator={isPrivateNotification ? Validators.empty() : undefined}
            onFocusChange={(f) => {
              setStreetnumFocus(f);
              firstTimeShowHintsForAddressFields.current = true;
            }}
            disabled={isDisabled}
          />
        </div>
        {decideToShowHint()}
        {!(streetFocus || streetNumFocus) &&
          (validations?.street?.valid === false || validations?.streetNum?.valid === false) && (
            <div className='error-field'>
              {validations?.street?.message || validations?.streetNum?.message}
            </div>
          )}
      </div>

      {withPostfach && (
        <PostFachInput
          className='w-full'
          value={addressProps?.postfach || ''}
          onChange={(v) =>
            onAddressChange({
              postfach: v,
            })
          }
          placeholder='Postfach'
          onError={(e) => {
            if (onError) {
              onError(e, 'postfach');
            }
          }}
          validation={validations?.postfach}
          optional
          inputMode='numeric'
          inputRegex={DECIMAL_REGEXP}
          onFocusChange={(f) => {
            setStreetFocus(f);
            firstTimeShowHintsForAddressFields.current = true;
          }}
          disabled={isDisabled}
        />
      )}

      <div className='postalCode-city-field'>
        <div className='input-field'>
          <TextInput
            className='w-50p'
            value={addressProps?.postalCode || ''}
            onChange={(v) =>
              onAddressChange({
                postalCode: v,
              })
            }
            placeholder='PLZ'
            inputMode='numeric'
            validator={Validators.zipCode()}
            onError={(e: ValidationResult) => {
              if (onError) {
                onError(e, 'postalCode');
              }
            }}
            validation={validations?.postalCode}
            disableErrorText
            onFocusChange={setPostalCodeFocus}
            disabled={isDisabled}
          />
          <TextInput
            className='w-50p'
            value={addressProps?.city || ''}
            onChange={(v) =>
              onAddressChange({
                city: v,
              })
            }
            placeholder='Ort'
            validator={Validators.moreThan2()}
            onError={(e: ValidationResult) => {
              if (onError) {
                onError(e, 'city');
              }
            }}
            validation={validations?.city}
            disableErrorText
            onFocusChange={setCityFocus}
            disabled={isDisabled}
          />
        </div>

        {!(postalCodeFocus || cityFocus) &&
          (validations?.fullAddress?.valid === false ||
            validations?.postalCode?.valid === false ||
            validations?.city?.valid === false) && (
            <div className='error-field'>
              {validations?.fullAddress?.message ||
                validations?.postalCode?.message ||
                validations?.city?.message}
            </div>
          )}
      </div>
    </div>
  );
};

export default AddressInput;
