import { FrontEndSection, Hint } from '../../stores/FrontendRule/FrontendRule.type';
import { ClosingDataModel } from '../Overview/ClosingDataModel';
import { CustomerDataModel } from '../Overview/Common';
import { GlobalDate } from '../Overview/GlobalDate';
import { NotificationModel } from '../Overview/NotificationModel';
import { Overview } from '../Overview/Overview';
import { StandingOrderModel } from '../Overview/StandingOrderModel';
import { Timeline } from '../Timeline';

export interface ManualSectionModel {
  id: string;
  normalizedCounterName: string;
}

export enum SubStateEnum {
  SENDING = 'SENDING',
  SENT = 'SENT',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
}
export interface ThanksPageModel {
  manualSection: {
    remindNotifications: ManualSectionModel[];
    manualNotifications: ManualSectionModel[];
    foreignNotifications: ManualSectionModel[];
  };
  notifications: NotificationModel[];
  standingOrders: StandingOrderModel[];
  closingData: ClosingDataModel;
  globalDate: GlobalDate;
  customerData: Partial<CustomerDataModel>;
  frontendRule: {
    hideSections?: FrontEndSection[];
    hints?: Hint[];
  };
  timeline: Timeline;
  processSubState?: SubStateEnum;
}

export const isThanksPageModel = (data: Overview | ThanksPageModel): data is ThanksPageModel =>
  data && 'manualSection' in data && 'timeline' in data;
