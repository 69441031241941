import { create } from 'zustand';
import { ToastStore } from './Toast.type';

export const useToastStore = create<ToastStore>((set) => ({
  toasts: [],
  actions: {
    addToast: (newToast) => set((state) => ({ toasts: [newToast, ...state.toasts.slice(0, 2)] })),
    removeToast: (key) =>
      set((state) => ({ toasts: state.toasts.filter((toast) => toast.id !== key) })),
  },
}));

export const useToasts = () => useToastStore((state) => state.toasts);
export const useToastActions = () => useToastStore((state) => state.actions);
