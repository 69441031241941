import React, { useState } from 'react';
import { ValidationResult, Validator } from '../../../../utils/validators';
import './index.scss';
import { ReactComponent as CarretIcon } from '../../../../assets/carret.svg';
import {
  checkErrorVisibility,
  processValidationIfOnError,
} from '../../../../utils/inputFieldHelpers';

interface Options {
  placeholder: string;
  value: string;
}

export interface SelectInputProps {
  placeholder: string;
  validator?: Validator;
  validation?: ValidationResult;
  className?: string;
  value: string;
  options?: Options[];
  onChange: (v: string) => void;
  onError?: (validation: ValidationResult) => void;
  emptyOption?: string;
  emptyOptionEnabled?: boolean;
}

const SelectInput = ({
  validator,
  onChange,
  onError,
  value: propsValue,
  validation,
  className,
  emptyOptionEnabled,
  emptyOption,
  options,
  placeholder,
}: SelectInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e: any) => {
    const v = e.target.value || '';
    const validationV = validator?.validate(v);
    onChange(v);

    processValidationIfOnError(onError, validationV);
    e.target.blur();
  };

  const showErrors = checkErrorVisibility(isFocused, validation?.valid);

  return (
    <div className={`select-input ${showErrors ? 'error ' : ''} ${className ?? ''}`}>
      <div className='inner'>
        <select
          className={propsValue ? 'has-value' : ''}
          value={propsValue || ''}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          data-testid='select-input-field'
        >
          {emptyOption && (
            <option
              value=''
              disabled={!emptyOptionEnabled}
              key='selector'
              data-testid='empty-option'
            >
              {emptyOption}
            </option>
          )}
          {options?.map((o) => (
            <option key={o.value} value={o.value}>
              {o.placeholder}
            </option>
          ))}
        </select>
        {propsValue ? <div className='floating-label'>{placeholder}</div> : null}
        <div className='append-inner'>
          <CarretIcon className={`dropdown-icon${isFocused ? ' open' : ''}`} />
        </div>
      </div>
      {showErrors && <div className='error-text'> {validation?.message} </div>}
    </div>
  );
};

export default SelectInput;
