import React, { useState } from 'react';

import AlertCard from '../../../Common/AlertCard';
import { useKwsState } from '../../../../stores/KwsState/KwsState.store';

const FidorBankHintSection = () => {
  const kwsState = useKwsState();
  const [showHint, setshowHint] = useState(
    localStorage.getItem('hideFidorBankHintSection') === null ||
      localStorage.getItem('hideFidorBankHintSection') !== kwsState!.id,
  );

  const close = () => {
    localStorage.setItem('hideFidorBankHintSection', kwsState!.id);
    setshowHint(false);
  };

  return (
    <AlertCard isOpen={showHint} close={close}>
      <div className='header'>Hinweis für Kunden der Fidor Bank:</div>
      <div className='text'>
        <div>
          Zur Schließung Ihres Fidor-Kontos brauchen Sie aktuell nichts unternehmen. Die Fidor Bank
          wird sich dazu direkt mit Ihnen in Verbindung setzen bzw. hat es bereits getan.
        </div>
      </div>
    </AlertCard>
  );
};

export default FidorBankHintSection;
