import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import Validators, { ValidationErrorNotification } from '../../../../../../utils/validators';
import {
  NotificationDetailModel,
  UpdateNotificationModel,
} from '../../../../../../types/Overview/NotificationModel';
import ServiceGateway from '../../../../../../services/ServiceGateway';
import { Gender, RecipientSearchModel } from '../../../../../../types/Overview/Common';
import Tooltip from '../../../../../Common/Tooltip';
import Checkbox from '../../../../../Common/Checkbox';
import DateInput from '../../../../../Common/InputFields/DateInput';
import InfoBox from '../../../../../Common/InfoBox';
import TextInput from '../../../../../Common/InputFields/TextInput';
import AddressInput, { AddressState } from '../../../../../Common/InputFields/AddressInput';
import ActionButtons from '../../../../../Common/ActionButtons';
import ContactCard from '../../../../../Common/ContactCard';
import './index.scss';
import AutoComplete from '../../../../../Common/Autocomplete';
import {
  isApprovedState,
  isProcessInThanksPage,
  setModifiedTransaction,
} from '../../../../../../utils/helper';
import useDebounce from '../../../../../../hooks/useDebounce';
import RecipientSearchItem from '../../../../../Common/Autocomplete/components/RecipientSearchItem';
import ChipButtonGroup from '../../../../../Common/ChipButtonGroup';
import { SPACE_REGEXP } from '../../../../../../utils/regex';
import {
  useKwsState,
  useKwsStateActions,
  useNotificationToProcess,
} from '../../../../../../stores/KwsState/KwsState.store';
import { WorkflowEnum } from '../../../../../../stores/KwsState/KwsState.type';
import { TooltipEnum } from '../../../../../../stores/Tooltip/Tooltip.type';
import Container from '../../../../../Common/Container';
import RoundButton from '../../../../../Common/RoundButton';
import ConfirmationModal from '../../../../CommonSections/ConfirmationModal';

export type NotificationDetailTabProps = {
  notificationDetailState: NotificationDetailModel;
  setNotificationDetailState: React.Dispatch<
    React.SetStateAction<NotificationDetailModel | undefined>
  >;
  validationError: ValidationErrorNotification;
  setValidationError: React.Dispatch<React.SetStateAction<ValidationErrorNotification>>;
};

const NotificationDetailTab = ({
  notificationDetailState,
  setNotificationDetailState,
  validationError,
  setValidationError,
}: NotificationDetailTabProps) => {
  const kwsState = useKwsState();
  const notificationToProcess = useNotificationToProcess();
  const { setNotificationToProcess, setSwitchingDateToProcess } = useKwsStateActions();

  const { pageType } = notificationToProcess!;
  const [showUserDetail, setShowUserDetail] = useState(false);
  const {
    id: notifId,
    recipient: notifRecipient,
    dates: notifDates,
    added: notifAdded,
    customerData: notifCustomerData,
    state: notifState,
    referenceNumber: notifReferenceNumber,
    private: notifPrivate,
    userPermission: notifUserPermission,
  } = notificationDetailState;

  const [isRecipientFillLoading, setIsRecipientFillLoading] = useState(false);
  const [possibleInputList, setPossibleInputList] = useState<RecipientSearchModel[]>([]);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  const [hasCancelModal, setHasCancelModal] = useState(false);
  const [selectedInputName, setSelectedInputName] = useState<string | undefined>(
    notifRecipient?.name,
  );
  const debouncedSearchText = useDebounce(notifRecipient?.name, 500);

  const [firstSwitchingDate, setFirstSwitchingDate] = useState<string>(notifDates?.switchingDate);

  const isEditPage = pageType === 'EDIT';

  const queryClient = useQueryClient();
  const { mutate: onSubmit, isPending: isActionButtonsLoading } = useMutation({
    mutationFn: async () => {
      const data: UpdateNotificationModel = {
        ...notificationDetailState,
        asap: notifDates?.asap,
        switchingDate: notifDates?.asap
          ? notifDates?.earliestSwitchingDate
          : notifDates?.switchingDate,
        recipient: {
          ...notifRecipient,
          email: notifRecipient?.email || undefined,
          fax: notifRecipient?.fax || undefined,
          address: {
            ...notifRecipient?.address,
            countryCode: notifRecipient?.address?.countryCode || 'DE',
            country: notifRecipient?.address?.country || 'DEUTSCHLAND',
          },
        },
      };

      let response;
      if (pageType === 'EDIT') {
        if (isProcessInThanksPage(kwsState!.state) && isApprovedState(notifState)) {
          response = await ServiceGateway.updateThanksPageNotification(kwsState!.id, data);
        } else {
          response = await ServiceGateway.updateNotification(kwsState!.id, data);
        }
      } else {
        response = await ServiceGateway.createNotification(kwsState!.id, data);
      }

      return response.data;
    },
    onSuccess: (data) => {
      if (isEditPage) {
        queryClient.invalidateQueries({
          queryKey: ['notification-details', kwsState!.id, notifId],
        });
      }
      setModifiedTransaction(data.id);
      setNotificationToProcess(undefined);
      setSwitchingDateToProcess(false);
    },
    onError: (error: any) => {
      if (error.response.status === 400) {
        if (error.response?.data?.errors) {
          setValidationError({
            ...validationError,
            ...error.response.data.errors.reduce(
              (acc: any, err: any) => ({
                ...acc,
                [err.type]: {
                  ...acc[err.type],
                  [err.key]: { valid: false, message: err.message },
                },
              }),
              {},
            ),
          });
        }
      }
    },
  });

  const { mutate: onCancelNotif, isPending: isCancelLoading } = useMutation({
    mutationFn: async () => {
      await ServiceGateway.cancelNotification(kwsState!.id, notifId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notification-details', kwsState!.id, notifId!] });
      setNotificationToProcess(undefined);
    },
    onError: () => {
      setNotificationToProcess(undefined);
    },
  });

  // telekom temporary disable edit
  const checkIfTelekom = () => {
    if (notifAdded === false && (notifRecipient?.id === 79589 || notifRecipient?.id === 163487)) {
      return true;
    }

    return false;
  };
  const [isTelekomEditDisabled] = useState(() => checkIfTelekom());

  useEffect(() => {
    const hasCustomerError =
      Object.keys(validationError).length === 0
        ? false
        : !(
            Object.keys(validationError?.FIELD ?? {})
              .filter((item) => item.indexOf('customerData') >= 0)
              .every((item) => validationError?.FIELD[item].valid) &&
            (validationError?.ADDRESS?.customerData?.valid ?? false)
          );
    if (hasCustomerError) {
      setShowUserDetail(true);
    }
  }, [validationError]);

  const onGenderSelect = (val: Gender) => {
    setNotificationDetailState({
      ...notificationDetailState,
      customerData: {
        ...notifCustomerData,
        gender: val,
      },
    });
  };

  const goBack = () => {
    setNotificationToProcess(undefined);
    setSwitchingDateToProcess(false);
  };

  const onCheck = (checked: boolean) => {
    if (checked) {
      setFirstSwitchingDate(notifDates.switchingDate);
    }
    setValidationError((validations) => ({
      ...validations,
      FIELD: {
        ...validations?.FIELD,
        switchingDate: { valid: true },
      },
    }));
    setNotificationDetailState({
      ...notificationDetailState,
      dates: {
        ...notifDates,
        asap: checked,
        switchingDate: checked ? notifDates.earliestSwitchingDate : firstSwitchingDate,
      },
    });
  };

  const hasGenderError =
    validationError.FIELD?.['customerData.gender'] &&
    !validationError.FIELD['customerData.gender'].valid;

  const errorCheck = !(
    notifDates.switchingDate &&
    notifRecipient?.name &&
    (notifRecipient?.address?.germanAddress === false
      ? true
      : (notifPrivate
          ? notifRecipient?.address?.street && notifRecipient?.address?.streetNum
          : true) &&
        notifRecipient?.address?.city &&
        notifRecipient?.address?.postalCode) &&
    notifCustomerData?.gender &&
    notifCustomerData?.lastName &&
    notifCustomerData?.firstName &&
    notifCustomerData?.mobileNumber &&
    notifCustomerData?.birthDate &&
    notifCustomerData?.email &&
    notifCustomerData?.address?.city &&
    notifCustomerData?.address?.zipCode &&
    Object.values(validationError?.FIELD ?? {}).every((item) => item.valid) &&
    Object.values(validationError?.ADDRESS ?? {}).every((item) => item.valid)
  );

  let isAccountClosingDatePassed = false;
  if (notificationToProcess?.accountClosingDate) {
    const dateToCompare = moment(notificationToProcess.accountClosingDate).startOf('day');
    isAccountClosingDatePassed = dateToCompare.isBefore(moment().startOf('day'));
  }

  const chipButtons = [
    {
      label: 'Frau',
      onClick: onGenderSelect,
      value: Gender.FEMALE,
      isActive: notifCustomerData.gender === Gender.FEMALE,
      hasError: hasGenderError,
    },
    {
      label: 'Herr',
      onClick: onGenderSelect,
      value: Gender.MALE,
      isActive: notifCustomerData.gender === Gender.MALE,
      hasError: hasGenderError,
    },
    {
      label: 'Keine Angabe',
      onClick: onGenderSelect,
      value: Gender.NA,
      isActive: notifCustomerData.gender === Gender.NA,
      hasError: hasGenderError,
    },
  ];

  // #region recipient search
  const renderDisplayValue = (data: RecipientSearchModel): JSX.Element => (
    <RecipientSearchItem itemData={data} searchText={debouncedSearchText || ''} />
  );

  const renderSelectableDisplayValue = (value: string): JSX.Element => (
    <RecipientSearchItem
      // pass the extra information as street to use the same component
      itemData={{ name: value, street: 'Zahlungspartner manuell hinzufügen' }}
      searchText={value}
    />
  );

  const searchPossibleManualRecipients = async (searchText: string) => {
    setIsRecipientFillLoading(true);
    setSearchErrorMessage('');

    const response = await ServiceGateway.getPossibleRecipientList(searchText);

    setPossibleInputList(response.data);
    setSearchErrorMessage(response.data.length === 0 ? 'Zahlungspartner nicht verfügbar.' : '');
    setIsRecipientFillLoading(false);
  };

  const selectRecipient = async (recipient?: RecipientSearchModel) => {
    setSearchErrorMessage('');

    if (recipient?.name) {
      setNotificationDetailState({
        ...notificationDetailState,
        recipient: {
          ...notifRecipient,
          name: recipient.name,
          id: recipient?.id,
          email: recipient?.email,
          fax: recipient?.fax,
          address: {
            ...notifRecipient?.address,

            street: recipient?.street,
            streetNum: recipient?.streetNum,
            postalCode: recipient?.postalCode,
            city: recipient?.city,
            country: recipient?.country,
            postfach: recipient?.postfach,
          },
        },
      });
      setSelectedInputName(recipient.name);
      setPossibleInputList([]);
    } else {
      setSelectedInputName(notifRecipient?.name);
      setPossibleInputList([]);
      setNotificationDetailState({
        ...notificationDetailState,
        recipient: {
          ...notifRecipient,
          id: '',
          email: '',
          fax: '',
          address: {
            ...notifRecipient?.address,
            street: '',
            streetNum: '',
            postalCode: '',
            city: '',
            country: '',
            postfach: '',
          },
        },
      });
    }
  };

  useEffect(() => {
    if (
      notifRecipient?.name &&
      notifRecipient?.name.length >= 2 &&
      notifRecipient?.name !== selectedInputName
    ) {
      if (pageType === 'ADD' || notifAdded) {
        searchPossibleManualRecipients(debouncedSearchText!);
      }
    } else {
      setPossibleInputList([]);
    }
  }, [debouncedSearchText, selectedInputName]);
  // #endregion

  return (
    <div className='notification-details-tab'>
      <div className='form-container'>
        <div className='description'>
          {isEditPage
            ? 'Für die Benachrichtigung des Zahlungspartners nutzen wir die folgenden Daten. Sie können dies individuell anpassen.'
            : 'Bitte tragen Sie die Daten des Partners ein, den wir zusätzlich über Ihre neue Bankverbindung informieren sollen.'}
        </div>

        {/* Contact data payment partner */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Kontaktdaten Zahlungspartner</div>
            <Tooltip tooltip={TooltipEnum.NOTIFICATION_DETAILS_ADDRESS} hasBackground>
              {kwsState!.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN ? (
                <p>
                  Tragen Sie hier die Kontaktdaten des Zahlungspartners ein.
                  <br />
                  <br />
                  Die Mitteilung an den Zahlungspartner über die Änderung Ihrer Bankverbindung wird
                  an diese Adresse geschickt.
                </p>
              ) : (
                <p>
                  Die vorausgefüllte Anschrift wurde automatisch ermittelt. Sie können die
                  Kontaktdaten des Zahlungspartners manuell anpassen. Diese erscheinen in der
                  Mitteilung an den Zahlungspartner über die Änderung Ihrer Bankverbindung.
                </p>
              )}
            </Tooltip>
          </div>
          <div className='form-input'>
            {isEditPage && !notifAdded ? (
              <TextInput
                className='w-full'
                value={notifRecipient?.name ?? ''}
                onChange={(v) =>
                  setNotificationDetailState({
                    ...notificationDetailState,
                    recipient: {
                      ...notifRecipient,
                      name: v,
                    },
                  })
                }
                placeholder='Name'
                validator={Validators.moreThan2()}
                onError={(e) =>
                  setValidationError((validations) => ({
                    ...validations,
                    FIELD: {
                      ...validations?.FIELD,
                      'recipient.name': e,
                    },
                  }))
                }
                validation={validationError.FIELD?.['recipient.name']}
                disabled={isTelekomEditDisabled}
              />
            ) : (
              <AutoComplete
                placeholder='Name'
                searchText={notifRecipient?.name || ''}
                setSearchText={(v) =>
                  setNotificationDetailState({
                    ...notificationDetailState,
                    recipient: {
                      ...notifRecipient,
                      name: v,
                    },
                  })
                }
                displayedValue={notifRecipient?.name || ''}
                loading={isRecipientFillLoading}
                possibleInputList={possibleInputList}
                errorMessage={searchErrorMessage}
                selectPossibleInput={selectRecipient}
                setPossibleInputList={setPossibleInputList}
                renderItemDisplayValue={renderDisplayValue}
                isSearchInputSelectable={
                  notifRecipient?.name !== selectedInputName
                    ? {
                        renderSelectableDisplayValue,
                      }
                    : undefined
                }
              />
            )}
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={notifRecipient?.department ?? ''}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  recipient: {
                    ...notifRecipient,
                    department: v,
                  },
                })
              }
              placeholder='Namenszusatz'
              validator={Validators.moreThan2orNull()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  FIELD: {
                    ...validations?.FIELD,
                    'recipient.department': e,
                  },
                }))
              }
              validation={validationError.FIELD?.['recipient.department']}
              optional
              disabled={isTelekomEditDisabled}
            />
          </div>
          <div className='form-input'>
            {notifRecipient?.address?.germanAddress === false ? (
              <TextInput
                className='w-full'
                value={notifRecipient?.address?.street ?? ''}
                onChange={(v) =>
                  setNotificationDetailState({
                    ...notificationDetailState,
                    recipient: {
                      ...notifRecipient,
                      address: {
                        ...notifRecipient?.address,
                        street: v,
                      },
                    },
                  })
                }
                placeholder='Anschrift'
                validator={Validators.moreThan2orNull()}
                onError={(e) =>
                  setValidationError((validations) => ({
                    ...validations,
                    FIELD: {
                      ...validations?.FIELD,
                      'recipient.street': e,
                    },
                  }))
                }
                validation={validationError?.FIELD?.['recipient.street']}
              />
            ) : (
              <AddressInput
                withPostfach
                addressProps={{
                  street: notifRecipient?.address?.street,
                  city: notifRecipient?.address?.city,
                  streetNum: notifRecipient?.address?.streetNum,
                  postalCode: notifRecipient?.address?.postalCode,
                  postfach: notifRecipient?.address?.postfach,
                }}
                validations={{
                  street: validationError.FIELD?.['recipient.address.street'],
                  city: validationError.FIELD?.['recipient.address.city'],
                  streetNum: validationError.FIELD?.['recipient.address.streetNum'],
                  postalCode: validationError.FIELD?.['recipient.address.postalCode'],
                  postfach: validationError.FIELD?.['recipient.address.postfach'],
                  fullAddress: validationError.ADDRESS?.recipient,
                }}
                onAddressChange={(v: AddressState) => {
                  setNotificationDetailState({
                    ...notificationDetailState,
                    recipient: {
                      ...notifRecipient,
                      address: {
                        ...notifRecipient?.address,
                        ...v,
                      },
                    },
                  });
                  setValidationError((validations) => ({
                    ...validations,
                    ADDRESS: {
                      ...validations?.ADDRESS,
                      recipient: { valid: true },
                    },
                  }));
                }}
                onError={(e: any, fieldName: any) =>
                  setValidationError((validations) => ({
                    ...validations,
                    FIELD: {
                      ...validations?.FIELD,
                      [`recipient.address.${fieldName}`]: e,
                    },
                  }))
                }
                showHintField={
                  pageType === 'ADD' || notifState === 'INCOMPLETE' || notifState === 'TO_COMPLETE'
                    ? 'INITIALLY'
                    : 'AFTER_FOCUS'
                }
                isPrivateNotification={notifPrivate}
                isDisabled={isTelekomEditDisabled}
              />
            )}
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={notifRecipient?.email ?? ''}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  recipient: {
                    ...notifRecipient,
                    email: v,
                  },
                })
              }
              placeholder='Email'
              validator={Validators.emailOrNull()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  FIELD: {
                    ...validations?.FIELD,
                    'recipient.email': e,
                  },
                }))
              }
              inputMode='email'
              validation={validationError?.FIELD?.['recipient.email']}
              optional
              inputRegex={SPACE_REGEXP}
              disabled={isTelekomEditDisabled}
            />
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={notifRecipient?.fax ?? ''}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  recipient: {
                    ...notifRecipient,
                    fax: v,
                  },
                })
              }
              placeholder='Fax'
              validator={Validators.phoneOrNull()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  FIELD: {
                    ...validations?.FIELD,
                    'recipient.fax': e,
                  },
                }))
              }
              validation={validationError?.FIELD?.['recipient.fax']}
              optional
              inputMode='tel'
              disabled={isTelekomEditDisabled}
            />
          </div>
        </div>

        {/* Reference number */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Ihre Referenznummer</div>
            <Tooltip tooltip={TooltipEnum.NOTIFICATION_DETAILS_REFERENCE_NUMBER} hasBackground>
              <p>
                Hier können Sie zum Beispiel Ihre Kundennummer, Referenznummer, Vertragsnummer oder
                Ähnliches eintragen, welche in der gewöhnlichen Kommunikation mit dem
                Zahlungspartner genutzt werden.
              </p>
            </Tooltip>
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={notifReferenceNumber}
              placeholder='Kundenreferenz'
              validator={Validators.moreThan2orNull()}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  referenceNumber: v,
                })
              }
              optional
            />
          </div>
        </div>

        {/* Switching date */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Umzugsdatum für diesen Zahlungspartner</div>
            <Tooltip tooltip={TooltipEnum.NOTIFICATION_DETAILS_HEADER} hasBackground>
              <p>
                Sie können für jeden einzelnen Zahlungspartner ein bestimmtes Datum wählen, ab dem
                Ihr neues Konto genutzt werden soll. Dieses erscheint in der Mittelung über die
                Änderung Ihrer Bankverbindung.
              </p>
            </Tooltip>
          </div>
          <div className='form-input switching-date'>
            <div className='date-wrapper'>
              <div className='asap-row'>
                <Checkbox
                  onChange={onCheck}
                  checked={notifDates?.asap}
                  inputId='asap-checkbox'
                  label='Schnellstmöglich informieren'
                />
              </div>
              <DateInput
                value={notifDates?.switchingDate}
                onChange={(v) => {
                  setNotificationDetailState({
                    ...notificationDetailState,
                    dates: {
                      ...notifDates,
                      switchingDate: v,
                    },
                  });
                }}
                validation={validationError?.FIELD?.switchingDate}
                onError={(e) =>
                  setValidationError((validations) => ({
                    ...validations,
                    FIELD: {
                      ...validations?.FIELD,
                      switchingDate: e,
                    },
                  }))
                }
                disabled={notifDates?.asap}
              />
            </div>
            {!notifDates?.asap && !isAccountClosingDatePassed && (
              <div className='switching-info'>
                Durch die Änderung des Umzugsdatums ändert sich das Datum der Kontoschließung, falls
                diese gewünscht ist. So werden mögliche Rückbuchungen vermieden.
              </div>
            )}
            {isAccountClosingDatePassed && (
              <div className='switching-info'>
                <b>Achtung:</b> die Schließung Ihres bisherigen Kontos liegt in der Vergangenheit.
                Es kann sein, dass dieser Zahlungspartner bis zum gewählten Kontoumzugsdatum nicht
                abbuchen kann.
              </div>
            )}
          </div>
          <InfoBox type='info'>
            <p>
              CHECK24 informiert Ihre Zahlungspartner rechtzeitig über Ihre neue Bankverbindung. In
              der Regel benötigen Zahlungspartner eine gewisse Zeit, um Ihren Kontoumzug zu
              bestätigen. Ein früheres Umzugsdatum ist deshalb nicht auswählbar.
            </p>
          </InfoBox>
        </div>

        {/* Personal data */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Ihre persönlichen Angaben</div>
            <Tooltip tooltip={TooltipEnum.NOTIFICATION_DETAILS_CUSTOMER} hasBackground>
              <p>
                Hier können Sie abweichende Kontaktdaten angeben. Zum Beispiel, falls der Vertrag
                mit dem Zahlungspartner auf dem Namen von Ihren Ehemann / Ehefrau / Kind läuft.
                <br />
                <br />
                Die Kontaktdaten, die Sie hier eintragen, erscheinen in der Mitteilung an den
                Zahlungspartner über die Änderung Ihrer Bankverbindung.
              </p>
            </Tooltip>
          </div>

          <div className='form-input'>
            <ChipButtonGroup buttons={chipButtons} groupLabel='Anrede' />
          </div>

          <div className='form-input'>
            <TextInput
              className='w-full'
              value={notifCustomerData?.firstName}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  customerData: {
                    ...notifCustomerData,
                    firstName: v,
                  },
                })
              }
              placeholder='Vorname'
              validator={Validators.moreThan2()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  FIELD: {
                    ...validations?.FIELD,
                    'customerData.firstName': e,
                  },
                }))
              }
              validation={validationError?.FIELD?.['customerData.firstName']}
            />
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={notifCustomerData?.lastName}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  customerData: {
                    ...notifCustomerData,
                    lastName: v,
                  },
                })
              }
              placeholder='Nachname'
              validator={Validators.moreThan2()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  FIELD: {
                    ...validations?.FIELD,
                    'customerData.lastName': e,
                  },
                }))
              }
              validation={validationError?.FIELD?.['customerData.lastName']}
            />
          </div>
          <div className='form-label'>Geburtsdatum</div>
          <div className='form-input'>
            <DateInput
              value={notifCustomerData?.birthDate}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  customerData: {
                    ...notifCustomerData,
                    birthDate: v,
                  },
                })
              }
              validation={validationError?.FIELD?.['customerData.birthDate']}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  FIELD: {
                    ...validations?.FIELD,
                    'customerData.birthDate': e,
                  },
                }))
              }
            />
          </div>
        </div>

        {showUserDetail && (
          <>
            {/* Customer Address */}
            <div className='form-group'>
              <div className='form-header'>
                <div className='title'>Ihre Anschrift</div>
              </div>
              <div className='form-input'>
                <AddressInput
                  addressProps={{
                    street: notifCustomerData?.address?.street,
                    city: notifCustomerData?.address?.city,
                    streetNum: notifCustomerData?.address?.streetNumber,
                    postalCode: notifCustomerData?.address?.zipCode,
                  }}
                  validations={{
                    street: validationError.FIELD?.['customerData.address.street'],
                    city: validationError.FIELD?.['customerData.address.city'],
                    streetNum: validationError.FIELD?.['customerData.address.streetNum'],
                    postalCode: validationError.FIELD?.['customerData.address.zipCode'],
                    fullAddress: validationError.ADDRESS?.customerData,
                  }}
                  onAddressChange={(v: AddressState) => {
                    setNotificationDetailState({
                      ...notificationDetailState,
                      customerData: {
                        ...notificationDetailState.customerData,
                        address: {
                          ...notificationDetailState.customerData?.address,
                          streetNumber:
                            v.streetNum ??
                            notificationDetailState.customerData?.address?.streetNumber,
                          street: v.street ?? notificationDetailState.customerData?.address?.street,
                          city: v.city ?? notificationDetailState.customerData?.address?.city,
                          zipCode:
                            v.postalCode ?? notificationDetailState.customerData?.address?.zipCode,
                        },
                      },
                    });
                    setValidationError((validations) => ({
                      ...validations,
                      ADDRESS: {
                        ...validations?.ADDRESS,
                        customerData: { valid: true },
                      },
                    }));
                  }}
                  onError={(e: any, fieldName: any) =>
                    setValidationError((validations) => ({
                      ...validations,
                      FIELD: {
                        ...validations?.FIELD,
                        [`customerData.address.${
                          fieldName === 'postalCode' ? 'zipCode' : fieldName
                        }`]: e,
                      },
                    }))
                  }
                />
              </div>
            </div>

            {/* Customer Contact data */}
            <div className='form-group'>
              <div className='form-header'>
                <div className='title'>Ihre Kontaktdaten</div>
              </div>
              <div className='form-input'>
                <TextInput
                  className='w-full'
                  value={notifCustomerData?.email}
                  onChange={(v) =>
                    setNotificationDetailState({
                      ...notificationDetailState,
                      customerData: {
                        ...notifCustomerData,
                        email: v,
                      },
                    })
                  }
                  placeholder='E-Mail'
                  validator={Validators.email()}
                  onError={(e) =>
                    setValidationError((validations) => ({
                      ...validations,
                      FIELD: {
                        ...validations?.FIELD,
                        'customerData.email': e,
                      },
                    }))
                  }
                  validation={validationError?.FIELD?.['customerData.email']}
                  inputRegex={SPACE_REGEXP}
                  inputMode='email'
                />
              </div>
              <div className='form-input'>
                <TextInput
                  className='w-full'
                  value={notifCustomerData?.mobileNumber}
                  onChange={(v) =>
                    setNotificationDetailState({
                      ...notificationDetailState,
                      customerData: {
                        ...notifCustomerData,
                        mobileNumber: v,
                      },
                    })
                  }
                  inputMode='tel'
                  placeholder='Mobilnummer'
                  validator={Validators.phone()}
                  validation={validationError?.FIELD?.['customerData.mobileNumber']}
                  onError={(e) =>
                    setValidationError((validations) => ({
                      ...validations,
                      FIELD: {
                        ...validations?.FIELD,
                        'customerData.mobileNumber': e,
                      },
                    }))
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>

      <ActionButtons
        isSticky
        isDisabled={errorCheck}
        isLoading={isActionButtonsLoading}
        proceedButton={{ label: 'Mitteilung speichern', onClick: onSubmit }}
        cancelButton={{ label: 'Änderungen verwerfen', onCancel: goBack }}
      />

      {notifUserPermission?.cancellable && (
        <Container>
          <div className='cancel-section'>
            <div>
              <div>Mitteilung stornieren</div>
              <div>
                Falls Sie diesen Zahlungspartner doch nicht über Ihre neue Bankverbinung informieren
                möchten, können Sie die Mitteilung aus Ihrem Kontoumzug entfernen.
              </div>
            </div>
            <RoundButton
              onClick={() => setHasCancelModal(true)}
              title='Mitteilung stornieren'
              isCancel
            />
          </div>
          {hasCancelModal && (
            <ConfirmationModal
              onCancel={() => {
                setHasCancelModal(false);
              }}
              onSubmit={onCancelNotif}
              isLoading={isCancelLoading}
              submitLabel='Ja, stornieren'
            >
              <div className='cancel-alert-modal'>
                <p>Mitteilung stornieren</p>
                <p>
                  Sind Sie sich sicher, dass Sie diesen Zahlungspartner doch nicht über Ihre neue
                  Bankverbindung informieren möchten?
                </p>
              </div>
            </ConfirmationModal>
          )}
        </Container>
      )}

      <ContactCard />
    </div>
  );
};

export default NotificationDetailTab;
