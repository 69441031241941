import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import ServiceGateway from '../../services/ServiceGateway';
import { KwsStateStore } from './KwsState.type';

export const useKwsStateStore = create<KwsStateStore>()(
  subscribeWithSelector((set, get) => ({
    kwsState: undefined,
    notificationToProcess: undefined,
    standingOrderToProcess: undefined,
    accountToProcess: undefined,
    switchingDateToProcess: false,

    actions: {
      setNotificationToProcess: (val) => set({ notificationToProcess: val }),
      setStandingOrderToProcess: (val) => set({ standingOrderToProcess: val }),
      setAccountToProcess: (val) => set({ accountToProcess: val }),
      setSwitchingDateToProcess: (val) => set({ switchingDateToProcess: val }),
      refreshKwsState: async (id, onSuccess) => {
        const currentKwsState = get().kwsState;
        const processId = id || currentKwsState?.id;

        if (!processId) return;

        const result = await ServiceGateway.getProcessState(processId);
        set({
          kwsState: {
            id: processId,
            state: result.data.processState,
            processNumber: result.data.processNumber,
            workflow: result.data.workflow,
            invalidSwitchingDates: result.data.invalidSwitchingDates,
            transactionTimeframe: result.data.transactionTimeframe,
            archived: result.data.archived,
            ibanSource: result.data.ibanSource,
          },
        });

        if (onSuccess) {
          onSuccess();
        }
      },
    },
  })),
);

export const useKwsState = () => useKwsStateStore((state) => state.kwsState);
export const useNotificationToProcess = () =>
  useKwsStateStore((state) => state.notificationToProcess);
export const useStandingOrderToProcess = () =>
  useKwsStateStore((state) => state.standingOrderToProcess);
export const useAccountToProcess = () => useKwsStateStore((state) => state.accountToProcess);
export const useSwitchingDateToProcess = () =>
  useKwsStateStore((state) => state.switchingDateToProcess);
export const useKwsStateActions = () => useKwsStateStore((state) => state.actions);
