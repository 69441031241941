import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import './index.scss';
import ServiceGateway from '../../../../services/ServiceGateway';
import ContactCard from '../../../Common/ContactCard';
import DateInput from '../../../Common/InputFields/DateInput';
import { ValidationResult } from '../../../../utils/validators';
import Tooltip from '../../../Common/Tooltip';
import ActionButtons from '../../../Common/ActionButtons';
import InfoBox from '../../../Common/InfoBox';
import WelcomeSection from './sections/WelcomeSection';
import {
  isProcessInThanksPage,
  isDesktopOrTablet,
  scrollToPosition,
  isApprovedState,
} from '../../../../utils/helper';
import ResetButton, { RESET_BUTTON_INTERSECTION } from '../../../Common/ResetButton';
import { IntersectionObserverComponent } from '../../../../hooks/useIntersectionObserver';
import { useKwsState, useKwsStateActions } from '../../../../stores/KwsState/KwsState.store';
import { TooltipEnum } from '../../../../stores/Tooltip/Tooltip.type';
import { useOverviewData } from '../../../../stores/Overview/Overview.store';
import ConfirmationModal from '../../CommonSections/ConfirmationModal';
import Container from '../../../Common/Container';
import RoundButton from '../../../Common/RoundButton';
import GoBackModal from '../../CommonSections/ConfirmationModal/GoBackModal';

const CloseAccountDetails = () => {
  const kwsState = useKwsState();
  const { setAccountToProcess, setSwitchingDateToProcess } = useKwsStateActions();

  const overviewData = useOverviewData();
  const { closingData, globalDate } = overviewData!;
  const [accountClosingDate, setAccountClosingDate] = useState(globalDate.accountClosingDate);
  const [validationError, setValidationError] = useState<Record<string, ValidationResult>>({});
  const [hasShadow, setHasShadow] = useState(false);
  const [hasCancelModal, setHasCancelModal] = useState(false);
  const [hasGoBackModal, setHasGoBackModal] = useState(false);

  const { mutate: onSubmit, isPending: isActionButtonsLoading } = useMutation({
    mutationFn: async () => {
      const account = {
        accountClosingDate,
        state: closingData.state,
        isNewBalanceDestinationAccount: false,
      };

      let response;

      if (isProcessInThanksPage(kwsState!.state) && isApprovedState(closingData.state)) {
        response = await ServiceGateway.updateThanksPageClosingDate(
          kwsState!.id,
          accountClosingDate,
        );
        return response?.data;
      }

      response = await ServiceGateway.closingData(kwsState!.id, account);

      return response?.data;
    },
    onSuccess: () => {
      setAccountToProcess(false);
      setSwitchingDateToProcess(false);
    },
    onError: (error: any) => {
      if (error.response.status === 400) {
        if (error.response?.data?.errors) {
          setValidationError({
            ...validationError,
            ...error.response.data.errors.reduce(
              (acc: any, err: any) => ({
                ...acc,
                [err.key]: { valid: false, message: err.message },
              }),
              {},
            ),
          });
        }
      }
    },
  });

  const { mutate: onCancelAC, isPending: isCancelLoading } = useMutation({
    mutationFn: async () => {
      await ServiceGateway.cancelAccountClosing(kwsState!.id);
    },
    onSuccess: () => {
      setAccountToProcess(false);
    },
    onError: () => {
      setAccountToProcess(false);
    },
  });

  const errorCheck = !Object.keys(validationError).every(
    (key) => validationError[key].valid ?? false,
  );

  const closePage = () => {
    setAccountToProcess(false);
    setSwitchingDateToProcess(false);
  };

  const goBack = () => {
    if (globalDate.accountClosingDate !== accountClosingDate) {
      setHasGoBackModal(true);
      return;
    }

    closePage();
  };

  useEffect(() => {
    scrollToPosition('0');
  }, []);

  return (
    <div className='close-account' data-testid='close-account'>
      <ResetButton
        title='zurück zur Übersicht'
        onClick={goBack}
        isSticky={!isDesktopOrTablet()}
        hasShadow={hasShadow}
      />

      <div className='intro-close-account'>
        <WelcomeSection oldBank={closingData.oldBank} />
      </div>
      {!isDesktopOrTablet() && (
        <IntersectionObserverComponent
          onIntersection={(isIntersecting) => setHasShadow(!isIntersecting)}
          placeOfIntersection={RESET_BUTTON_INTERSECTION}
        />
      )}

      {/* Form */}
      <div className='form-container'>
        <div className='description'>
          Sie können individuell wählen, wann Ihr bisheriges Konto geschlossen und der Restsaldo mit
          Ihrem neuen Konto verrechnet werden soll.
        </div>

        {/* New Account */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Datum der Kontoschließung</div>
            <Tooltip tooltip={TooltipEnum.ACCOUNT_CLOSING_FORM} hasBackground>
              <p>
                Negative Salden können nur dann verrechnet werden, wenn auf dem neuen Konto ein
                Dispokredit in entsprechender Höhe eingerichtet wurde. Der Kontoumzug kann einen
                bestehenden Dispokredit nicht automatisch auf das neue Konto übertragen. Ohne
                Dispokredit muss ein negativer Saldo vor der Kontoschließung ausgeglichen werden.
              </p>
            </Tooltip>
          </div>
          <div className='form-input'>
            <DateInput
              value={accountClosingDate}
              onChange={(v) => setAccountClosingDate(v)}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  switchingDate: e,
                }))
              }
              validation={validationError?.switchingDate}
            />
          </div>
          <InfoBox type='info'>
            <p>
              Das frühestmögliche Datum zur Schließung Ihres bisherigen Kontos wurde automatisch
              errechnet. Erfahrungsgemäß benötigen die Zahlungspartner eine gewisse Zeit, um Ihren
              Kontoumzug zu bestätigen. Aus diesem Grund ist eine frühere Schließung des bisherigen
              Kontos nicht auswählbar.
            </p>
          </InfoBox>
        </div>
      </div>

      <ActionButtons
        isDisabled={errorCheck}
        isLoading={isActionButtonsLoading}
        proceedButton={{ label: 'speichern', onClick: onSubmit }}
        cancelButton={{ label: 'Änderungen verwerfen', onCancel: goBack }}
      />

      {isProcessInThanksPage(kwsState!.state) && closingData.userPermission?.cancellable && (
        <Container>
          <div className='cancel-section' data-testid='account-closing-cancel-section'>
            <div>
              <div>Kontoschließung stornieren</div>
              <div>
                Falls Sie Ihr bisheriges Girokonto doch nicht kündigen möchten, können Sie die
                Kontoschließung aus Ihrem Kontoumzug entfernen.
              </div>
            </div>
            <RoundButton
              onClick={() => setHasCancelModal(true)}
              title='Kontoschließung stornieren'
              isCancel
            />
          </div>
          {hasCancelModal && (
            <ConfirmationModal
              onCancel={() => {
                setHasCancelModal(false);
              }}
              onSubmit={onCancelAC}
              isLoading={isCancelLoading}
              submitLabel='Ja, stornieren'
            >
              <div
                className='account-closing-cancel-modal'
                data-testid='account-closing-cancel-modal'
              >
                <p>Kontoschließung stornieren</p>
                <p>
                  Sind Sie sich sicher, dass Sie Ihr bisheriges Konto doch nicht kündigen möchten?
                </p>
              </div>
            </ConfirmationModal>
          )}
        </Container>
      )}

      {hasGoBackModal && (
        <GoBackModal onCancel={() => setHasGoBackModal(false)} onSubmit={closePage} />
      )}

      <ContactCard />
    </div>
  );
};

export default CloseAccountDetails;
