import { OnErrorType } from '../types/Input';
import { ValidationResult } from './validators';

export const checkFocus = (ref?: any) => ref?.current === document.activeElement;

export const processValidationIfOnError = (
  onError?: OnErrorType,
  validation: ValidationResult = { valid: true },
) => {
  if (!onError) return;
  onError(validation);
};

export const focusToRef = (ref: any) => ref?.current?.focus();

export const checkErrorVisibility = (focus: boolean, isValid?: boolean) =>
  isValid === false && !focus;
