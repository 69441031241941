import React from 'react';
import Button from '../Common/Button';
import ContactCard from '../Common/ContactCard';
import './index.scss';

interface NotPossibleProps {
  message?: string;
}

const NotPossible: React.FC<NotPossibleProps> = ({ message }: NotPossibleProps) => (
  <div className='not-possible'>
    <div className='content'>
      <img src='/app/error-dog.png' alt='' />
      <p>
        {message || (
          <>
            Ein unbekannter Fehler ist aufgetreten.
            <br />
            Bitte versuchen Sie es später erneut.
          </>
        )}
      </p>

      <Button
        onClick={() => {
          if (document.referrer) {
            window.location.href = document.referrer;
          } else {
            window.history.back();
          }
        }}
        label='Zurück'
      />
    </div>
    <ContactCard />
  </div>
);

export default NotPossible;
