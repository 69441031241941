import React from 'react';
import { C24AccountType } from '../../../../types/Overview/ClosingDataModel';
import './index.scss';

interface C24AccountChipProps {
  accountType: C24AccountType;
}

const C24AccountChip = ({ accountType }: C24AccountChipProps) => {
  const handleChipName = () => {
    switch (accountType) {
      case C24AccountType.GIRO:
        return 'Hauptkonto';
      case C24AccountType.JOINT_GIRO:
        return 'Gemeinschaftskonto';
      case C24AccountType.POCKET:
        return 'Pocket';
      case C24AccountType.JOINT_POCKET:
        return 'Gemeinschaftspocket';
      default:
        return '';
    }
  };

  return (
    <div className='c24-account-chip' data-testid='c24-account-chip'>
      <span className='chip-text' data-testid='chip-text'>
        {handleChipName()}
      </span>
    </div>
  );
};

export default C24AccountChip;
