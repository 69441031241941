import React from 'react';
import './index.scss';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow.svg';
import BankCard from '../BankCard';
import { OverviewBankModel } from '../../../types/Overview/ClosingDataModel';
import { isDesktopOrTablet } from '../../../utils/helper';

interface TransferBankCardProps {
  oldBank: OverviewBankModel;
  newBank: OverviewBankModel;
}
const TransferBankCard = ({ oldBank, newBank }: TransferBankCardProps) => {
  const isTop = !isDesktopOrTablet();
  const hasAnyBankChip = oldBank.accountType || newBank.accountType;
  return (
    <div
      className={`transfer-card ${isTop ? '' : 'desktop'} ${hasAnyBankChip ? 'bank-card-padding' : ''}`}
      data-testid='transfer-card-page'
    >
      <BankCard isTop={isTop} bankData={oldBank} />
      <div className='arrow'>
        <ArrowIcon className='arrow-icon' />
      </div>
      <BankCard isTop={isTop} bankData={newBank} />
    </div>
  );
};

export default TransferBankCard;
