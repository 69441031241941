import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Entry } from '../Entry';
import NotPossible from '../NotPossible';
import Landing from '../Landing';
import Restricted from '../Kws/Restricted';
import KwsBody from './KwsBodyWrapper';
import queryClientWrapper, { defaultQueryConfig } from '../../utils/queryClientWrapper';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Landing />} />
      <Route path='/start/landing' element={<Landing />} />
      <Route
        path='/start/app'
        element={
          <KwsBody>
            {queryClientWrapper(
              <Entry />,
              true, // It is not a test query client
              {
                defaultOptions: {
                  queries: {
                    ...defaultQueryConfig,
                  },
                },
              },
            )}
          </KwsBody>
        }
      />
      <Route
        path='/start/rejected-process'
        element={
          <KwsBody>
            <Restricted message='Ihr CHECK24 Kontoumzug konnte nicht gestartet werden, da Ihr neues Konto nicht eröffnet wurde.' />
          </KwsBody>
        }
      />
      <Route
        path='*'
        element={
          <KwsBody>
            <NotPossible />
          </KwsBody>
        }
      />
    </Routes>
  </BrowserRouter>
);

export default App;
