import React from 'react';
import './index.scss';

import Container from '../../../../Common/Container';
import { ReactComponent as CheckIcon } from '../../../../../assets/check.svg';
import { formatDate, isApprovedState } from '../../../../../utils/helper';
import Tooltip from '../../../../Common/Tooltip';
import { NotificationModel } from '../../../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../../../types/Overview/StandingOrderModel';
import StateInfoChips from '../../../../Common/StateInfoChips';
import { UserStatusEnum } from '../../../../../types/SignaturePage/SignaturePageModel';
import DefaultSuccessSection from './components/DefaultSuccessSection';
import InfoBox from '../../../../Common/InfoBox';
import { useKwsState } from '../../../../../stores/KwsState/KwsState.store';
import { ProcessStateEnum } from '../../../../../stores/KwsState/KwsState.type';
import { TooltipEnum } from '../../../../../stores/Tooltip/Tooltip.type';
import { useOverviewData } from '../../../../../stores/Overview/Overview.store';
import JointAccountAlert from '../../../../Common/JointAccountAlert';
import { createStateChipData } from './stateChipContent';
import { isThanksPageModel } from '../../../../../types/ThanksPage/ThanksPageModel';

const SuccessComponentTooltip: React.JSX.Element = (
  <Tooltip tooltip={TooltipEnum.SUCCESS_SECTION}>
    <p>
      Im Folgenden haben wir für Sie nochmals alle durchgeführten Umzugsschritte zusammengefasst.
      Einige davon benötigen ggf. noch Ihre Mithilfe.
    </p>
    <br />
    <p>
      Bei Bedarf können wir jederzeit bisher noch nicht ausgewählte Umzugsschritte für Sie
      durchführen. Scrollen Sie dazu einfach zum Abschnitt „Umzugsschritte hinzufügen“ auf dieser
      Seite.
    </p>
  </Tooltip>
);

interface SuccessSectionProps {
  userState: UserStatusEnum;
}

const SuccessSection = ({ userState }: SuccessSectionProps) => {
  const overviewData = useOverviewData();
  const kwsState = useKwsState();

  if (!overviewData || (overviewData && !isThanksPageModel(overviewData))) {
    return null;
  }

  const { globalDate, notifications, standingOrders, closingData, customerData } = overviewData;

  const anyApprovedState = (data: NotificationModel[] | StandingOrderModel[]): boolean =>
    data.some((item: NotificationModel | StandingOrderModel) => isApprovedState(item.state));

  const isAccountClosingOnly =
    isApprovedState(closingData.state) &&
    !(anyApprovedState(notifications) || anyApprovedState(standingOrders));

  const createStateChip = (): React.JSX.Element | null => {
    const chipData = createStateChipData(
      kwsState!.state,
      closingData.newBank.name,
      overviewData?.processSubState,
    );
    if (chipData) {
      return (
        <StateInfoChips type={chipData.type} label={chipData.label}>
          {chipData.text}
        </StateInfoChips>
      );
    }

    return null;
  };

  return (
    <Container>
      {userState === UserStatusEnum.READY || userState === UserStatusEnum.UPDATED ? (
        <div className='success-section'>
          <div className='success'>
            <div className='success-title'>
              <CheckIcon className='check-icon' />
              <h2 data-testid='success-msg' className='text'>
                {isAccountClosingOnly
                  ? 'Vielen Dank, Ihre Kontoschließung wurde beauftragt'
                  : `Vielen Dank, Ihr Kontoumzug wurde ${
                      userState === UserStatusEnum.UPDATED ? 'aktualisiert' : 'beauftragt'
                    }`}
              </h2>
            </div>
            {SuccessComponentTooltip}
          </div>
          <div data-testid='ready-updated-content'>
            <p className='title'>
              Wir haben Ihnen eine Bestätigung an <span>{customerData.email}</span> gesendet.
            </p>
            <div className='account-info'>
              <div className='row'>
                <span>Vorgangsnummer</span>
                <span className='data'>{kwsState!.processNumber}</span>
              </div>
              <div className='row'>
                <span>{isAccountClosingOnly ? 'Kontoschließung' : 'Kontoumzugsdatum'}</span>
                <span className='data' data-testid='date-info'>
                  {globalDate.allSwitchingDatesMatch ? '' : 'ab '}
                  {isAccountClosingOnly
                    ? formatDate(globalDate.accountClosingDate)
                    : formatDate(globalDate.globalSwitchingDate)}
                </span>
              </div>
              <div className='row'>
                <span>Status</span>
                {createStateChip()}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <DefaultSuccessSection
          isAccountClosingOnly={isAccountClosingOnly}
          stateChip={createStateChip()}
          tooltip={SuccessComponentTooltip}
        />
      )}

      {kwsState?.state === ProcessStateEnum.WAITING_FOR_IBAN_FROM_BANK && (
        <div className='success-info-box-wrapper' data-testid='success-waiting-for-iban'>
          <InfoBox type='info'>
            <p>
              Die {closingData.newBank.name} übermittelt Ihre neue IBAN automatisch innerhalb von
              wenigen Tagen, sodass Ihr Kontoumzug durchgeführt werden kann.
            </p>
          </InfoBox>
        </div>
      )}

      <JointAccountAlert />
    </Container>
  );
};

export default SuccessSection;
