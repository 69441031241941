import React, { useState } from 'react';
import './index.scss';
import Container from '../../../../Common/Container';
import Tooltip from '../../../../Common/Tooltip';
import AlertCardV2 from '../../../../Common/AlertCardV2';
import InnerBorderContainer from '../../../../Common/InnerBorderContainer';
import { StandingOrderModel } from '../../../../../types/Overview/StandingOrderModel';
import {
  isThanksPageModel,
  ManualSectionModel,
} from '../../../../../types/ThanksPage/ThanksPageModel';
import ManualKwsAlertSection from './components/ManualKwsAlertSection';
import StandingOrderListItem from './components/StandingOrderListItem';
import { formatDate } from '../../../../../utils/helper';
import InfoBox from '../../../../Common/InfoBox';
import {
  FrontEndSection,
  HintSection,
  HintTypes,
} from '../../../../../stores/FrontendRule/FrontendRule.type';
import { externalHyperlinkGenerator } from '../../../../../utils/linkGenerator';
import { useFrontendRuleActions } from '../../../../../stores/FrontendRule/FrontendRule.store';
import { useKwsState } from '../../../../../stores/KwsState/KwsState.store';
import { WorkflowEnum } from '../../../../../stores/KwsState/KwsState.type';
import { TooltipEnum } from '../../../../../stores/Tooltip/Tooltip.type';
import { useOverviewData } from '../../../../../stores/Overview/Overview.store';

const AlertSection = () => {
  const kwsState = useKwsState();
  const { decideSectionToShow, decideHintToShow } = useFrontendRuleActions();

  const [showManualNotifications, setShowManualNotifications] = useState(
    !!(
      sessionStorage.getItem('c24-alert-manual') &&
      sessionStorage.getItem('c24-alert-manual') === 'true'
    ),
  );
  const [showIgnoredForeignCountryNotifications, setShowIgnoredForeignCountryNotifications] =
    useState(
      !!(
        sessionStorage.getItem('c24-alert-ignore') &&
        sessionStorage.getItem('c24-alert-ignore') === 'true'
      ),
    );
  const [showRemindNotifications, setShowRemindNotifications] = useState(
    !!(
      sessionStorage.getItem('c24-alert-remind') &&
      sessionStorage.getItem('c24-alert-remind') === 'true'
    ),
  );
  const [showStandingOrders, setShowStandingOrders] = useState(
    !!(
      sessionStorage.getItem('c24-alert-orders') &&
      sessionStorage.getItem('c24-alert-orders') === 'true'
    ),
  );

  const [showAccountClosingReminder, setShowAccountClosingReminder] = useState(
    !!(
      sessionStorage.getItem('c24-alert-account-closing') &&
      sessionStorage.getItem('c24-alert-account-closing') === 'true'
    ),
  );

  const [showCheck24AlertDetail, setShowCheck24AlertDetail] = useState(
    !!(
      sessionStorage.getItem('c24Bank-alert') && sessionStorage.getItem('c24Bank-alert') === 'true'
    ),
  );

  const overviewData = useOverviewData();

  if (!overviewData || (overviewData && !isThanksPageModel(overviewData))) {
    return null;
  }

  const { notifications, standingOrders, closingData, manualSection, globalDate } = overviewData;
  const { manualNotifications, remindNotifications, foreignNotifications } = manualSection;

  const showManualNotificationsAlert = manualNotifications?.length > 0;
  const showIgnoredForeignCountryNotificationsAlert = foreignNotifications?.length > 0;
  const showRemindNotificationsAlert = remindNotifications?.length > 0;
  const showStandingOrdersAlert =
    !decideSectionToShow(FrontEndSection.STANDING_ORDERS) && standingOrders?.length > 0;
  const ssoIbanChangeNotPossibleForC24 = decideHintToShow({
    type: HintTypes.SECTION,
    hint: HintSection.SSO_IBAN_CHANGE_NOT_POSSIBLE_FOR_C24,
  });

  const showAccountClosingAlert =
    !decideSectionToShow(FrontEndSection.CLOSE_ACCOUNT) &&
    decideHintToShow({
      type: HintTypes.SECTION,
      hint: HintSection.SOURCE_BANK_DOESNT_SUPPORT_CLOSE_ACCOUNT,
    });

  if (
    !(
      showManualNotificationsAlert ||
      showIgnoredForeignCountryNotificationsAlert ||
      showRemindNotificationsAlert ||
      showStandingOrdersAlert ||
      ssoIbanChangeNotPossibleForC24 ||
      showAccountClosingAlert ||
      kwsState!.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN
    )
  ) {
    return null;
  }

  const showManualKWSNotificationAlert = !(
    showManualNotificationsAlert ||
    showIgnoredForeignCountryNotificationsAlert ||
    showRemindNotificationsAlert ||
    notifications?.length > 0
  );

  const toggleManualNotifications = () => {
    sessionStorage.setItem('c24-alert-manual', (!showManualNotifications).toString());
    setShowManualNotifications(!showManualNotifications);
  };

  const toggleIgnoredNotifications = () => {
    sessionStorage.setItem(
      'c24-alert-ignore',
      (!showIgnoredForeignCountryNotifications).toString(),
    );
    setShowIgnoredForeignCountryNotifications(!showIgnoredForeignCountryNotifications);
  };

  const toggleRemindNotifications = () => {
    sessionStorage.setItem('c24-alert-remind', (!showRemindNotifications).toString());
    setShowRemindNotifications(!showRemindNotifications);
  };

  const toggleOrders = () => {
    sessionStorage.setItem('c24-alert-orders', (!showStandingOrders).toString());
    setShowStandingOrders(!showStandingOrders);
  };

  const toggleAccountClosing = () => {
    sessionStorage.setItem('c24-alert-account-closing', (!showAccountClosingReminder).toString());
    setShowAccountClosingReminder(!showAccountClosingReminder);
  };

  const toggleCheck24Detail = () => {
    sessionStorage.setItem('c24Bank-alert', (!showCheck24AlertDetail).toString());
    setShowCheck24AlertDetail(!showCheck24AlertDetail);
  };

  return (
    <Container>
      <div className='alert-section' data-testid='alert-section'>
        <div className='header'>
          <div className='title'>
            <div className='col-1'>
              <h2>Ihre persönlichen Aufgaben</h2>
            </div>
            <Tooltip tooltip={TooltipEnum.ALERT_SECTION}>
              {showAccountClosingAlert && (
                <>
                  <p>
                    Einige Banken erlauben die Kontoschließung nur durch den Kontoinhaber selbst.
                  </p>
                  <br />
                </>
              )}
              <p>
                Bei bestimmten Zahlungspartnern muss die Änderung der Bankverbindung direkt im
                persönlichen Online-Konto vorgenommen werden.
              </p>
              <br />
              <p>
                Etwaige Zahlungspartner im Ausland werden vom Kontoumzug noch nicht unterstützt.
              </p>
              <br />
              <p>
                Bitte denken Sie auch daran, Ihre neue Debitkarte bei Apple Pay oder Google Pay zu
                verknüpfen.
              </p>
            </Tooltip>
          </div>
          <InfoBox type='alert' title='Diese Umzugsschritte benötigen Ihre Mithilfe' />
        </div>
        <InnerBorderContainer>
          {showAccountClosingAlert && (
            <AlertCardV2
              show={showAccountClosingReminder}
              title='Kontoschließung bei der bisherigen Bank'
              onChange={toggleAccountClosing}
            >
              <div className='child'>
                {`Leider ist es uns nicht möglich, die Schließung Ihres ${
                  closingData.oldBank?.name
                } Kontos stellvertretend für Sie vorzunehmen.
                Die Bank sieht vor, dass eine Kontoschließung ausschließlich durch den 
                Kontoinhaber persönlich erfolgen kann. Bitte schließen Sie das Konto am ${formatDate(
                  globalDate.accountClosingDate,
                )}, damit Ihre Zahlungspartner bis dahin
                weiterhin von Ihrem Altkonto abbuchen können. Denken Sie auch daran den Restbetrag
                auf Ihr neues Konto zu überweisen.`}
              </div>
              {closingData.oldBank?.accountClosingLink && (
                <div className='account-closing-link'>
                  Eine Anleitung zur Löschung Ihres {closingData.oldBank.name} Kontos finden Sie
                  hier: {externalHyperlinkGenerator(closingData.oldBank.accountClosingLink)}
                </div>
              )}
            </AlertCardV2>
          )}

          {showManualNotificationsAlert && (
            <AlertCardV2
              show={showManualNotifications}
              title='Zahlungspartner ohne CHECK24 Kontoumzug'
              onChange={toggleManualNotifications}
            >
              <div className='child'>
                <div>
                  Folgende Zahlungspartner nehmen aktuell leider noch nicht am CHECK24 Kontoumzug
                  teil. Bitte aktualisieren Sie Ihre Kontoinformationen dort selbstständig.
                </div>
                <ul>
                  {manualSection?.manualNotifications.map((t: ManualSectionModel) => (
                    <li key={t.id}>{t.normalizedCounterName} </li>
                  ))}
                </ul>
              </div>
            </AlertCardV2>
          )}

          {showIgnoredForeignCountryNotificationsAlert && (
            <AlertCardV2
              show={showIgnoredForeignCountryNotifications}
              title='Zahlungspartner im Ausland'
              onChange={toggleIgnoredNotifications}
            >
              <div className='child'>
                <div>Leider können wir Ihre Zahlungspartner im Ausland aktuell nicht umziehen.</div>
                <ul>
                  {manualSection?.foreignNotifications.map((t: ManualSectionModel) => (
                    <li key={t.id}>{t.normalizedCounterName}</li>
                  ))}
                </ul>
              </div>
            </AlertCardV2>
          )}

          {showRemindNotificationsAlert && (
            <AlertCardV2
              show={showRemindNotifications}
              title='Mobiles Bezahlen'
              onChange={toggleRemindNotifications}
            >
              <div className='child'>
                <div>
                  Bitte denken Sie daran Ihre neue Debitkarte fürs mobile bezahlen zu hinterlegen.
                </div>
                <ul>
                  {manualSection?.remindNotifications.map((t: ManualSectionModel) => (
                    <li key={t.id}>{t.normalizedCounterName} </li>
                  ))}
                </ul>
              </div>
            </AlertCardV2>
          )}

          {showStandingOrdersAlert && (
            <AlertCardV2
              show={showStandingOrders}
              title='Einzurichtende Daueraufträge'
              onChange={toggleOrders}
            >
              <div className='child'>
                {decideHintToShow({
                  type: HintTypes.SECTION,
                  hint: HintSection.TARGET_BANK_DOESNT_SUPPORT_STANDING_ORDERS,
                })
                  ? `Die ${closingData.newBank?.name} unterstützt keine automatische Einrichtung von Daueraufträgen. Bitte richten Sie Ihre Daueraufträge dort selbstständig ein. Vergessen Sie nicht, Ihre Daueraufträge bei der bisherigen Bank zu löschen.`
                  : `Die ${closingData.oldBank?.name} unterstützt keine automatische Löschung von Daueraufträgen. Um Doppelbuchungen zu vermeiden, werden Ihre ausgewählten Daueraufträge nicht bei der neuen Bank eingerichtet. Bitte richten Sie Ihre Daueraufträge dort selbstständig ein.`}
                <ul>
                  {standingOrders.map((t: StandingOrderModel) => (
                    <StandingOrderListItem key={t.id} order={t} />
                  ))}
                </ul>
              </div>
            </AlertCardV2>
          )}

          {ssoIbanChangeNotPossibleForC24 && (
            <AlertCardV2
              show={showCheck24AlertDetail}
              title='CHECK24 Kundenkonto aktualisieren'
              onChange={toggleCheck24Detail}
            >
              <div className='child'>
                Leider konnten wir Ihre Bankverbindung nicht automatisiert in Ihrem CHECK24
                Kundenkonto aktualisieren, da es nicht mit Ihrem C24 Bank Girokonto verknüpft ist.
                Bitte ändern Sie Ihre IBAN dort selbstständig.
                <br />
                <br />
                <a
                  href='https://kundenbereich.check24.de/ul/?o=payment'
                  target='_blank'
                  rel='noreferrer'
                >
                  zum CHECK24 Kundenkonto
                </a>
              </div>
            </AlertCardV2>
          )}

          {kwsState!.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN && (
            <ManualKwsAlertSection
              closingData={closingData}
              showNotificationAlert={showManualKWSNotificationAlert}
            />
          )}
        </InnerBorderContainer>
      </div>
    </Container>
  );
};

export default AlertSection;
