import React, { useEffect, useState } from 'react';
import './index.scss';
import { formatIbanLook } from '../../../hooks/useIbanFormat';
import { OverviewBankModel } from '../../../types/Overview/ClosingDataModel';
import C24AccountChip from './C24AccountChip';

interface BankCardProps {
  /** Defines if the logo is on top of the bank info (mobile) */
  isTop?: boolean;
  /**
   * Logo name is expected from the BE, in the assets folder we try to match the logoNames with the bank logos.
   *
   * accountType: It can be giro/joint_giro/pocket_plus/joint_pocket which we only have in C24 bank data.
   */
  bankData: OverviewBankModel;
}

const BankCard = ({ isTop = false, bankData }: BankCardProps) => {
  const [logoSrc, setLogoSrc] = useState<string>('');
  const { logoName, iban, accountType, accountName, name } = bankData;

  const importLogo = async () => {
    try {
      const logo = await import(`../../../assets/banks/${logoName}.svg`);
      setLogoSrc(logo.default);
    } catch (error) {
      const fallbackLogo = await import('../../../assets/banks/bank-default.svg');
      setLogoSrc(fallbackLogo.default);
    }
  };

  const getIbanText = () => {
    if (iban) {
      return formatIbanLook(iban);
    }
    return 'IBAN wird nachgereicht';
  };

  const isDefault = logoSrc.includes('bank-default');

  useEffect(() => {
    importLogo();
  }, [logoName]);

  return (
    <div className={`bank ${isTop ? 'top' : ''}`} data-testid='bank-card-page'>
      {accountType && <C24AccountChip accountType={accountType} />}
      <div className={`logo ${isDefault ? '' : 'without-circle'}`}>
        <img
          className={`logo-image ${isDefault ? 'with-circle' : ''}`}
          src={logoSrc}
          alt='bank-logo'
          data-testid='image-test'
        />
      </div>
      <div className={`bank-data ${isTop ? 'top' : ''}`}>
        <p className={`bank-name line-clamp-1 ${isTop ? 'top' : ''}`} data-testid='name-test'>
          {`${name} ${bankData?.accountName ? `- ${accountName}` : ''}`}
        </p>
        <p className='bank-iban' data-testid='iban-test'>
          {getIbanText()}
        </p>
      </div>
    </div>
  );
};

export default BankCard;
