import React, { useState } from 'react';
import './index.scss';
import ServiceGateway from '../../../../services/ServiceGateway';

import Container from '../../../Common/Container';
import { isApprovedState } from '../../../../utils/helper';
import Tooltip from '../../../Common/Tooltip';
import { ItemState } from '../../../../types/Overview/Common';
import { ReactComponent as CheckIcon } from '../../../../assets/check.svg';
import RadioButtonGroup, {
  RadioButtonGroupLayoutDirection,
  RadioProps,
} from '../../../Common/InputFields/RadioButtonGroup';
import AccountClosingItem from './components/AccountClosingItem';
import { useKwsState } from '../../../../stores/KwsState/KwsState.store';
import { TooltipEnum } from '../../../../stores/Tooltip/Tooltip.type';
import { useOverviewActions, useOverviewData } from '../../../../stores/Overview/Overview.store';

enum CloseAccount {
  CLOSE = 'CLOSE',
  KEEP = 'KEEP',
}

type AccountClosingSectionProps = {
  selectionErrorState?: {
    selectionError?: string;
    setSelectionError: (error: string | undefined) => void;
  };
};

const AccountClosingSection = ({ selectionErrorState }: AccountClosingSectionProps) => {
  const kwsState = useKwsState();
  const { updateClosingData } = useOverviewActions();
  const overviewData = useOverviewData();
  const { closingData } = overviewData!;
  const [isRadioChangeDisabled, setIsRadioChangeDisabled] = useState(false);

  if (isApprovedState(closingData.state)) {
    return null;
  }

  const onRadioChange = (buttonValue: CloseAccount) => {
    const isClosing = buttonValue === CloseAccount.CLOSE;

    setIsRadioChangeDisabled(true);
    ServiceGateway.editClosingData(kwsState!.id, isClosing).then((res) => {
      setIsRadioChangeDisabled(false);
      updateClosingData(res.data);
      if (selectionErrorState) {
        selectionErrorState.setSelectionError(undefined);
      }
    });
  };

  const isClosingDateToBeNotified = closingData.state === ItemState.NOTIFY;

  const radioButtons: RadioProps[] = [
    {
      onChange: onRadioChange,
      value: CloseAccount.CLOSE,
      label: 'Ja',
      name: 'close-account',
      checked: isClosingDateToBeNotified,
      disabled: isRadioChangeDisabled,
    },

    {
      onChange: onRadioChange,
      value: CloseAccount.KEEP,
      label: 'Nein',
      name: 'close-account',
      checked: closingData.state === ItemState.IGNORE,
      disabled: isRadioChangeDisabled,
    },
  ];

  return (
    <Container>
      <div className='account-closing-section' data-testid='account-closing-section'>
        <div className='section-header'>
          <div className='header-block'>
            <h2>Kündigung des bisherigen Girokontos</h2>
            <Tooltip tooltip={TooltipEnum.ACCOUNT_CLOSING_HEADER}>
              <p>
                Hier können Sie die Kündigung Ihres bisherigen Girokontos beauftragen. Andere
                Finanzprodukte (z.B. Kreditkarten, Tagesgeldkonten, etc.) werden über den Kontoumzug
                nicht gekündigt.
              </p>
              <br />
              <p>
                Das frühestmögliche Datum zur Schließung Ihres bisherigen Girokontos wird
                automatisch errechnet. Sie können auch ein späteres Datum auswählen.
              </p>
            </Tooltip>
          </div>
          <p>Möchten Sie nach dem Kontoumzug Ihr bisheriges Girokonto kündigen?</p>
        </div>

        <RadioButtonGroup
          buttons={radioButtons}
          layoutDirection={RadioButtonGroupLayoutDirection.HORIZONTAL}
          hasError={!!selectionErrorState?.selectionError}
        />

        {selectionErrorState?.selectionError && (
          <p className='selection-error'>{selectionErrorState.selectionError}</p>
        )}

        {isClosingDateToBeNotified && (
          <div className='details-section'>
            <AccountClosingItem />

            <div className='warnings check'>
              <CheckIcon className='modified-icon' />
              <p>
                Der Restsaldo wird bei Kontoschließung automatisch mit Ihrem neuen Konto verrechnet.
              </p>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default AccountClosingSection;
