import React from 'react';
import './index.scss';
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';
import { ReactComponent as HelpIcon } from '../../../assets/help.svg';
import { useSelectedTooltip, useTooltipActions } from '../../../stores/Tooltip/Tooltip.store';
import { TooltipEnum } from '../../../stores/Tooltip/Tooltip.type';

interface TooltipProps {
  children?: React.ReactNode;
  tooltip: TooltipEnum;
  hasBackground?: boolean;
}

const Tooltip = ({ children, tooltip, hasBackground }: TooltipProps) => {
  const { selectTooltip } = useTooltipActions();
  const selectedTooltip = useSelectedTooltip();
  const isSelectedTooltip = tooltip === selectedTooltip;

  const handleClick = () => {
    selectTooltip(tooltip);
  };

  return (
    <div className='tooltip-wrapper' data-testid='tooltip-item'>
      <HelpIcon
        className={`help-icon ${isSelectedTooltip ? 'focused' : ''}`}
        onClick={handleClick}
        data-testid='tooltip-help-icon'
      />
      {isSelectedTooltip && (
        <>
          <div
            className={`desktop-only tooltip tooltip-shown ${hasBackground ? 'detail-page' : ''}`}
          >
            {children}
          </div>
          <div
            className='mobile-only overlay shown'
            onClick={handleClick}
            data-testid='tooltip-item-mobile'
          >
            <div className='popup'>
              <CrossIcon className='close' />
              <div
                className='content'
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                {children}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Tooltip;
