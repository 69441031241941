import React from 'react';
import './index.scss';

import Container from '../../../../Common/Container';
import { formatDate, setLastScrollPosition } from '../../../../../utils/helper';
import Tooltip from '../../../../Common/Tooltip';
import InnerBorderContainer from '../../../../Common/InnerBorderContainer';
import { ReactComponent as CalendarIcon } from '../../../../../assets/calendar.svg';
import { ReactComponent as CarretIcon } from '../../../../../assets/carret.svg';
import { useKwsStateActions } from '../../../../../stores/KwsState/KwsState.store';
import { TooltipEnum } from '../../../../../stores/Tooltip/Tooltip.type';
import { useOverviewData } from '../../../../../stores/Overview/Overview.store';

const SwitchingDateSection = () => {
  const { setSwitchingDateToProcess } = useKwsStateActions();
  const overviewData = useOverviewData();
  const { globalDate } = overviewData!;

  const openSwitchingDateEditPage = () => {
    setLastScrollPosition();
    setSwitchingDateToProcess(true);
  };

  return (
    <Container>
      <div className='switching-date-section' data-testid='switching-date-section'>
        <div className='section-header'>
          <div className='header-block'>
            <h2>Kontoumzugsdatum</h2>
            <Tooltip tooltip={TooltipEnum.SWITCHING_DATE}>
              <p>
                Wir haben das schnellstmögliche Umzugsdatum vorgemerkt. Dieses berücksichtigt die
                notwendigen Bearbeitungszeiten Ihrer Zahlungspartner und Ihrer bisherigen bzw. neuen
                Bank.
              </p>
              <br />
              <p>Sie können auch ein späteres Umzugsdatum auswählen.</p>
            </Tooltip>
          </div>
          <p>Ab welchem Stichtag sollen Ihre Zahlungspartner Ihr neues Konto nutzen?</p>
        </div>
        <InnerBorderContainer>
          <div
            className='switching-date'
            onClick={openSwitchingDateEditPage}
            data-testid='switching-date'
          >
            <div>
              <CalendarIcon />
            </div>
            <div className='wrapper'>
              <div className='details'>
                <div className='date'>{formatDate(globalDate.globalSwitchingDate)}</div>
                {globalDate.asap && <div className='information'>Schnellstmöglich</div>}
              </div>
              <div className='carret-color'>
                <CarretIcon className='rotate-90' />
              </div>
            </div>
          </div>
        </InnerBorderContainer>
      </div>
    </Container>
  );
};

export default SwitchingDateSection;
