import React, { useState } from 'react';
import './index.scss';
import { ReactComponent as SortIcon } from '../../../assets/sort.svg';
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';
import Button from '../Button';
import RadioButtonGroup, {
  RadioButtonGroupLayoutDirection,
  RadioProps,
} from '../InputFields/RadioButtonGroup';

interface SortProps {
  selected: any;
  relevancy: string;
  onChange: (value: any) => void;
}

const Sort = ({ selected, relevancy, onChange }: SortProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [sortValue, setSortValue] = useState(selected || 'amount');
  const [selectedV, setSelected] = useState(selected || 'amount');

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const onSort = (value: any) => {
    setSortValue(value);
  };

  const sort = () => {
    onChange(sortValue);
    setSelected(sortValue);
    setShowMenu(!showMenu);
  };

  const translate = (value: any) => {
    switch (value) {
      case 'amount':
        return 'Betrag';
      case 'frequency':
        return 'Frequenz';
      case 'category':
        return 'Kategorie';
      case 'alphabetical':
        return 'Alphabet';
      default:
        return '';
    }
  };

  const relevantButtons =
    relevancy !== 'irrelevant'
      ? [
          {
            onChange: onSort,
            name: relevancy,
            label: 'Kategorie',
            value: 'category',
            checked: sortValue === 'category',
          },
          {
            onChange: onSort,
            name: relevancy,
            label: 'Alphabet',
            value: 'alphabetical',
            checked: sortValue === 'alphabetical',
          },
        ]
      : [];

  const radioButtons: RadioProps[] = [
    {
      onChange: onSort,
      name: relevancy,
      label: 'Betrag',
      value: 'amount',
      checked: sortValue === 'amount',
    },
    {
      onChange: onSort,
      name: relevancy,
      label: 'Frequenz',
      value: 'frequency',
      checked: sortValue === 'frequency',
    },
    ...relevantButtons,
  ];

  return (
    <div className='sort-container'>
      <div onClick={toggleMenu} className='sort'>
        <SortIcon className='sort-icon' />
        <div className='sort-text'>
          {selectedV && selectedV !== 'amount' && <div className='indicator' />}
          <div>Sortieren</div>
          <div className='subtext'>{translate(selectedV)}</div>
        </div>
      </div>
      {showMenu ? (
        <div className='sort-modal'>
          <div className='sort-modal-menu'>
            <CrossIcon
              onClick={() => {
                setShowMenu(false);
              }}
              className='close'
            />
            <div className='sort-modal-menu-content'>
              <RadioButtonGroup
                buttons={radioButtons}
                groupLabel='Sortieren nach'
                layoutDirection={RadioButtonGroupLayoutDirection.VERTICAL}
                isWithoutBorder
              />

              <Button onClick={sort} label='Sortierung übernehmen' />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Sort;
