import React, { useEffect } from 'react';
import Dac from './Dac';
import Overview from './Overview';
import Thanks from './Thanks';
import DigitalSignature from './DigitalSignature';
import NotPossible from '../NotPossible';
import NotificationDetails from './DetailPages/NotificationDetails';
import AccountClosingDetails from './DetailPages/AccountClosingDetails';
import StandingOrderDetails from './DetailPages/StandingOrderDetails';
import SwitchingDateDetails from './DetailPages/SwitchingDateDetails';
import InvalidSwitchingDates from './InvalidSwitchingDates';
import SpinnerFullPage from '../Common/SpinnerFullPage';
import Restricted from './Restricted';
import './index.scss';
import { isProcessInThanksPage, scrollToPosition } from '../../utils/helper';
import {
  useAccountToProcess,
  useKwsState,
  useKwsStateActions,
  useNotificationToProcess,
  useStandingOrderToProcess,
  useSwitchingDateToProcess,
} from '../../stores/KwsState/KwsState.store';
import { ProcessStateEnum } from '../../stores/KwsState/KwsState.type';
import { useSelectedTooltip } from '../../stores/Tooltip/Tooltip.store';
import ToastWrapper from '../Common/ToastWrapper';

let interval: any;
const Kws = () => {
  const kwsState = useKwsState();
  const notificationToProcess = useNotificationToProcess();
  const standingOrderToProcess = useStandingOrderToProcess();
  const switchingDateToProcess = useSwitchingDateToProcess();
  const accountToProcess = useAccountToProcess();
  const {
    setNotificationToProcess,
    setSwitchingDateToProcess,
    setStandingOrderToProcess,
    setAccountToProcess,
    refreshKwsState,
  } = useKwsStateActions();
  const selectedTooltip = useSelectedTooltip();

  useEffect(() => {
    if (kwsState!.state !== ProcessStateEnum.DAC_NEEDED) {
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = () => {
        scrollToPosition('0');
        setNotificationToProcess(undefined);
        setStandingOrderToProcess(undefined);
        setAccountToProcess(false);
        setSwitchingDateToProcess(false);
        window.history.pushState(null, '', window.location.href);
      };
    }
    if (kwsState!.state === ProcessStateEnum.DAC_ANALYSIS_NEEDED) {
      interval = setInterval(() => {
        refreshKwsState();
      }, 3000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [kwsState!.state]);

  const decideWhichPageToShow = () => {
    const noEditItemSelected =
      !notificationToProcess &&
      !accountToProcess &&
      !switchingDateToProcess &&
      !standingOrderToProcess;

    const statesWithDetailPages =
      kwsState!.state === ProcessStateEnum.EDITING ||
      kwsState!.state === ProcessStateEnum.READY ||
      kwsState!.state === ProcessStateEnum.WAITING_FOR_IBAN_FROM_BANK ||
      kwsState!.state === ProcessStateEnum.WAITING_FOR_IBAN_FROM_CUSTOMER;

    // order below this line is important. be careful!!!
    if (kwsState!.state === ProcessStateEnum.CANCELED) {
      return <Restricted message='Ihr CHECK24 Kontoumzug wurde storniert' />;
    }
    if (kwsState!.archived) {
      if (kwsState!.state === ProcessStateEnum.READY) {
        return <Restricted success message='Ihr CHECK24 Kontoumzug wurde durchgeführt!' />;
      }
      return <Restricted message='Leider ist Ihr CHECK24 Kontoumzug abgelaufen' />;
    }
    if (kwsState!.invalidSwitchingDates) {
      return <InvalidSwitchingDates />;
    }
    if (kwsState!.state === ProcessStateEnum.NOT_POSSIBLE) {
      return <NotPossible />;
    }
    if (kwsState!.state === ProcessStateEnum.DAC_NEEDED) {
      return <Dac />;
    }
    if (kwsState!.state === ProcessStateEnum.DAC_ANALYSIS_NEEDED) {
      return (
        <SpinnerFullPage text='Bitte haben Sie etwas Geduld, wir analysieren Ihre Kontoumsätze. Dies kann mehrere Minuten dauern.' />
      );
    }
    if (kwsState!.state === ProcessStateEnum.EDITING && noEditItemSelected) {
      return (
        <ToastWrapper>
          <Overview />
        </ToastWrapper>
      );
    }
    if (isProcessInThanksPage(kwsState!.state) && noEditItemSelected) {
      return (
        <ToastWrapper>
          <Thanks />
        </ToastWrapper>
      );
    }
    if (statesWithDetailPages && notificationToProcess) {
      return <NotificationDetails />;
    }
    if (statesWithDetailPages && standingOrderToProcess) {
      return <StandingOrderDetails />;
    }
    if (statesWithDetailPages && accountToProcess) {
      return <AccountClosingDetails />;
    }
    if (statesWithDetailPages && switchingDateToProcess) {
      return <SwitchingDateDetails />;
    }
    if (kwsState!.state === ProcessStateEnum.SIGNING) {
      return <DigitalSignature />;
    }

    return <NotPossible />;
  };

  return (
    <div className={`kws ${selectedTooltip ? 'desktop-side-view' : ''}`}>
      {decideWhichPageToShow()}
    </div>
  );
};

export default Kws;
